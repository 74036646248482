import React, { useEffect, useState } from 'react'
import header from '../../Images/Pages/header.png'
import footer from '../../Images/Pages/footer.png'
import axios from 'axios'
import {
  ApiEndPoint,
  Hotelapitoken
} from '../../Components/GlobalData/GlobalData'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import Loading from '../../Components/Loading/Loader'
import moment from 'moment'
function BookingVoucher () {
  const baseurl = ApiEndPoint()
  const token = Hotelapitoken()
  const { id } = useParams()
  const navigation = useNavigate()
  const [invoiceDate, setInvoiceDate] = useState({})
  const [hideContact, setHideContact] = useState(false);
  const [isLoading, setIsLoading] = useState(true)
  const [transportDetail, setTransportDetail] = useState([])
  const [companyName, setCompanyName] = useState('')
  const [serviceType, setServiceType] = useState('')
  const [transportDetailMore, setTransportDetailMore] = useState([])
  const [flightDetails, setFlightDetails] = useState([])
  const [ziaratDetails, setZiaratDetails] = useState([])
  const [moreVisaDetail, setMoreVisaDetail] = useState([])
  const [returnflightDetails, setReturnFlightDetails] = useState([])
  const [flightCostingDetails, setFlightCostingDetails] = useState([])
  const [accomodationDetail, setAccomodationDetail] = useState([])
  const [accomodationDetailMore, setAccomodationDetailMore] = useState([])
  var UserDetail = JSON.parse(localStorage.getItem('agentdata'))
  useEffect(() => {
    window.scroll(0,0)
    if (UserDetail === null) {
      navigation('/login')
      return
    }
    GetInvoiceData()
  }, [])

  const GetInvoiceData = async () => {
    var data = {
      token: token,
      b2b_Agent_Id: UserDetail.id,
      invoice_Id: id
    }
    try {
      const response = await axios.post(
        baseurl + '/api/invoice_Agent_B2B',
        data
      )
      setIsLoading(false)
      if (response.data.message === 'success') {
        // console.log(response.data)
        setInvoiceDate(response.data.invoice_Data)
        const service = JSON.parse(response.data.invoice_Data?.services)
        if (service && Array.isArray(service)) {
          let serviceTypeString = ''

          service.forEach(service => {
            if (service === '1') {
              serviceTypeString = 'All Services'
            } else if (service === 'accomodation_tab') {
              serviceTypeString += 'Hotel, '
            } else if (service === 'transportation_tab') {
              serviceTypeString += 'Transfer, '
            } else if (service === 'flights_tab') {
              serviceTypeString += 'Flight, '
            } else if (service === 'visa_tab') {
              serviceTypeString += 'Visa, '
            }
          })

          // Remove the trailing comma and space
          serviceTypeString = serviceTypeString.replace(/, $/, '')
          setServiceType(serviceTypeString)
        }
        if (
          response.data.invoice_Data?.b2b_Agent_Company_Name &&
          response.data.invoice_Data?.b2b_Agent_Company_Name !== null &&
          response.data.invoice_Data?.b2b_Agent_Company_Name !== ''
        ) {
          setCompanyName(response.data.invoice_Data?.b2b_Agent_Company_Name)
        } else if (
          response.data.invoice_Data?.agent_Name &&
          response.data.invoice_Data?.agent_Name !== null &&
          response.data.invoice_Data?.agent_Name !== ''
        ) {
          setCompanyName(response.data.invoice_Data?.agent_Name)
        }
        if (response.data?.invoice_Data?.accomodation_details !== null &&
          response.data?.invoice_Data?.accomodation_details_more !== '') {
          setAccomodationDetail(
            JSON.parse(response.data?.invoice_Data?.accomodation_details)
          )
        }
        if (
          response.data?.invoice_Data?.accomodation_details_more !== null &&
          response.data?.invoice_Data?.accomodation_details_more !== 'null' &&
          response.data?.invoice_Data?.accomodation_details_more !== ''
        ) {
          setAccomodationDetailMore(
            JSON.parse(response.data?.invoice_Data?.accomodation_details_more)
          )
        }
        if (response.data?.invoice_Data?.flights_details !== null) {
          setFlightDetails(
            JSON.parse(response.data?.invoice_Data?.flights_details)
          )
        }
        if (response.data?.invoice_Data?.return_flights_details !== null) {
          setReturnFlightDetails(
            JSON.parse(response.data?.invoice_Data?.return_flights_details)
          )
        }
        if (response.data?.invoice_Data?.more_visa_details !== null) {
          setMoreVisaDetail(
            JSON.parse(response.data?.invoice_Data?.more_visa_details)
          )
        }
        if (response.data?.invoice_Data?.flights_Pax_details !== null) {
          setFlightCostingDetails(
            JSON.parse(response.data?.invoice_Data?.flights_Pax_details)
          )
        }
        if (response.data?.invoice_Data?.transportation_details !== null) {
          const transferlist = JSON.parse(
            response.data?.invoice_Data?.transportation_details
          )
          var newtransferlist = transferlist.filter(
            item => item.transportation_pick_up_location !== ''
          )
          setTransportDetail(newtransferlist)
        }
        if (response.data?.invoice_Data?.transportation_details_more !== null) {
          setTransportDetailMore(
            JSON.parse(response.data?.invoice_Data?.transportation_details_more)
          )
        }
        if (
          response.data?.invoice_Data?.ziyarat_details !== null &&
          response.data?.invoice_Data?.ziyarat_details !== ''
        ) {
          setZiaratDetails(
            JSON.parse(response.data?.invoice_Data?.ziyarat_details)
          )
        }
      }
    } catch (error) {
      setIsLoading(false)
      console.log(error)
    }
  }
  const withoutcontact =()=>{
    setHideContact(true); // Hide contact details
    setTimeout(() => {
      window.print(); // Trigger the print dialog
      setHideContact(false); // Reset after printing
    }, 500); // Delay to ensure print dialog shows before resetting
  }
  return (
    <>
      {isLoading && <Loading />}
      <div class='px-3'>
        <img style={{ width: '100%' }} src={header} alt='letterhead' />
      </div>
      <div class='vochure-content px-3'>
        <div className='row'>
          <div className='col-md-8 col-sm-12'>
            <section class='vochure-detail-section '>
              <div class='Invoice-detail-top row'>
                <div class='col-sm-6 col-md-4'>
                  <div class='invoice-d border-right'>
                    {invoiceDate.confirm !== 1 ? (
                      <>
                        {invoiceDate.cancellation_Status !== 1 ? (
                          <h3 style={{ color: '#519e0a' }}>Tentative</h3>
                        ) : (
                          <h3 className='text-danger'>Cancelled</h3>
                        )}
                      </>
                    ) : (
                      <h3 style={{ color: '#519e0a' }}>Confirmed</h3>
                    )}
                    <p>
                      Invoice NO : <span>{invoiceDate?.generate_id}</span>
                    </p>
                  </div>
                </div>

                <div class='col-sm-6 col-md-4'>
                  <div class='invoice-d border-right'>
                    <img src='https://admin.umrahshop.com/public/calendar.png' />
                    <p className='mt-3'>
                      Booking Date :{' '}
                      <span>
                        {moment(invoiceDate?.created_at).format('DD-MM-YYYY')}
                      </span>
                    </p>
                  </div>
                </div>

                <div class='col-sm-6 col-md-4'>
                  <div class='invoice-d'>
                    <img src='https://admin.umrahshop.com/public/night1.png' />
                    <p className='mt-3'>
                      Duration: :
                      <span>{invoiceDate?.time_duration} Nights</span>
                    </p>
                  </div>
                </div>

                <div class='col-sm-6 col-md-4'>
                  <div class='invoice-d border-right'>
                    <img src='https://admin.umrahshop.com/public/services.png' />
                    <p>
                      Service Type
                      <br />
                      <span>{serviceType}</span>
                    </p>
                  </div>
                </div>

                <div class='col-sm-6 col-md-4'>
                  <div class='invoice-d border-right'>
                    <img src='https://admin.umrahshop.com/public/user.png' />
                    <p>
                      Company Name
                      <br/>
                      <span>{companyName}</span>
                    </p>
                  </div>
                </div>

                <div class='col-sm-6 col-md-4'>
                  <div class='invoice-d'>
                    <img src='https://admin.umrahshop.com/public/user.png' />
                    <p>
                      {' '}
                      Created By {' '}<br/>
                      <span>
                        Agent - {UserDetail.first_name} {UserDetail.last_name}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              {accomodationDetail.length !== 0 && (
                <>
                  <div class='v-heading-icon bg-title clearfix mt-3'>
                    <div class='float-left'>
                      <img src='https://admin.umrahshop.com//public/uploads/package_imgs/hotel.png' />
                    </div>
                    <div class='main-title-section float-left'>
                      <h3>Hotels Details</h3>
                    </div>
                  </div>
                  {accomodationDetail.map((item, index) => (
                    <div key={index}>
                      <h5 className='my-4 px-3'>
                        Hotel Name : {item?.acc_hotel_name}
                      </h5>
                      <div
                        class='clearfix v-section-info'
                        style={{ border: '1px solid #ddd' }}
                      >
                        <div class='row'>
                          <div class='col-md-6'>
                            <ul class='list-items list-items-3 list-items-4  clearfix'>
                              <li>
                                <span
                                  class='text-black font-weight-bold'
                                  style={{ width: '50%' }}
                                >
                                  Res #:
                                </span>
                                {item?.room_reservation_no}
                              </li>
                              <li>
                                <span
                                  class='text-black font-weight-bold'
                                  style={{ width: '50%' }}
                                >
                                  Room Type:
                                </span>
                                {item?.acc_type}
                              </li>
                              <li>
                                <span
                                  class='text-black font-weight-bold'
                                  style={{ width: '50%' }}
                                >
                                  Room Quantity:
                                </span>
                                {item?.acc_qty}
                              </li>
                            </ul>
                          </div>
                          <div class='col-md-6'>
                            <ul class='list-items list-items-3 list-items-4  clearfix'>
                              <li>
                                <span
                                  class='text-black font-weight-bold'
                                  style={{ width: '50%' }}
                                >
                                  Check In:
                                </span>
                                {moment(item?.acc_check_in).format(
                                  'DD-MM-YYYY'
                                )}
                              </li>
                              <li>
                                <span
                                  class='text-black font-weight-bold'
                                  style={{ width: '50%' }}
                                >
                                  Check Out:
                                </span>
                                {moment(item?.acc_check_out).format(
                                  'DD-MM-YYYY'
                                )}
                              </li>
                              <li>
                                <span
                                  class='text-black font-weight-bold'
                                  style={{ width: '50%' }}
                                >
                                  No of Nights:
                                </span>
                                {item?.acc_no_of_nightst}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                  {accomodationDetailMore.map((item, index) => (
                    <div key={index}>
                      <div
                        class='clearfix v-section-info'
                        style={{ border: '1px solid #ddd' }}
                      >
                        <div class='row'>
                          <div class='col-md-6'>
                            <ul class='list-items list-items-3 list-items-4  clearfix'>
                              <li>
                                <span
                                  class='text-black font-weight-bold'
                                  style={{ width: '50%' }}
                                >
                                  Res #:
                                </span>
                                {item?.more_room_reservation_no}
                              </li>
                              <li>
                                <span
                                  class='text-black font-weight-bold'
                                  style={{ width: '50%' }}
                                >
                                  Room Type:
                                </span>
                                {item?.more_acc_type}
                              </li>
                              <li>
                                <span
                                  class='text-black font-weight-bold'
                                  style={{ width: '50%' }}
                                >
                                  Room Quantity:
                                </span>
                                {item?.more_acc_qty}
                              </li>
                            </ul>
                          </div>
                          <div class='col-md-6'>
                            <ul class='list-items list-items-3 list-items-4  clearfix'>
                              <li>
                                <span
                                  class='text-black font-weight-bold'
                                  style={{ width: '50%' }}
                                >
                                  Check In:
                                </span>
                                {moment(item?.more_acc_check_in).format(
                                  'DD-MM-YYYY'
                                )}
                              </li>
                              <li>
                                <span
                                  class='text-black font-weight-bold'
                                  style={{ width: '50%' }}
                                >
                                  Check Out:
                                </span>
                                {moment(item?.more_acc_check_out).format(
                                  'DD-MM-YYYY'
                                )}
                              </li>
                              <li>
                                <span
                                  class='text-black font-weight-bold'
                                  style={{ width: '50%' }}
                                >
                                  No of Nights:
                                </span>
                                {item?.more_acc_no_of_nightst}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              )}
              {/* Flight Details */}
              {returnflightDetails.length !== 0 && (
                <>
                  <div class='v-heading-icon bg-title clearfix mt-3'>
                    <div class='float-left'>
                      <img src='https://admin.umrahshop.com/public/invoice/assets/img/flight.png' />
                    </div>
                    <div class='main-title-section float-left'>
                      <h3>Departure Flight Details</h3>
                    </div>
                  </div>
                  {flightDetails.map((item, index) => (
                    <div key={index} className='mx-3'>
                      <div className='my-2'>
                        <h6>
                          {item?.departure_airport_code}{' '}
                          <i className='fa-solid fa-plane'></i>{' '}
                          {item?.arrival_airport_code} (
                          {item?.departure_flight_route_type})
                        </h6>
                        <p>Airline Name : {item?.other_Airline_Name2}</p>
                      </div>
                      <div className='row'>
                        <div className='col-md-4 p-4 col-12 d-flex flex-column justify-content-center align-items-center'>
                          <span>{item?.other_Airline_Name2}</span>
                          <p>{item?.departure_flight_number}</p>
                          <span>{item?.departure_flight_route_type}</span>
                        </div>
                        <div className='col-md-4 col-12 d-flex flex-column justify-content-center align-items-center'>
                          <span>
                            <i class='fa-solid fa-plane-departure'></i>
                            {item?.departure_airport_code}
                          </span>
                          <p>{moment(item.departure_time).format('LT')}</p>
                          <span>
                            {moment(item.departure_time).format('DD-MM-YYYY')}
                          </span>
                        </div>
                        <div className='col-md-4 col-12 d-flex flex-column justify-content-center align-items-center'>
                          <span>
                            <i class='fa-solid fa-plane-arrival'></i>
                            {item?.arrival_airport_code}
                          </span>
                          <p>{moment(item.arrival_time).format('LT')}</p>
                          <span>
                            {moment(item.arrival_time).format('DD-MM-YYYY')}
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              )}
              {returnflightDetails.length !== 0 && (
                <>
                  <div class='v-heading-icon bg-title clearfix mt-3'>
                    <div class='float-left'>
                      <img src='https://admin.umrahshop.com/public/invoice/assets/img/flight.png' />
                    </div>
                    <div class='main-title-section float-left'>
                      <h3>Return Flight Details</h3>
                    </div>
                  </div>
                  {returnflightDetails.map((item, index) => (
                    <div key={index} className='mx-3'>
                      <div className='my-2'>
                        <h6>
                          {item?.return_departure_airport_code}{' '}
                          <i className='fa-solid fa-plane'></i>{' '}
                          {item?.return_arrival_airport_code} (
                          {item?.return_flight_route_type})
                        </h6>
                        <p>Airline Name : {item?.return_other_Airline_Name2}</p>
                      </div>
                      <div className='row'>
                        <div className='col-md-4 p-4 col-12 d-flex flex-column justify-content-center align-items-center'>
                          <span>{item?.return_other_Airline_Name2}</span>
                          <p>{item?.return_departure_flight_number}</p>
                          <span>{item?.return_flight_route_type}</span>
                        </div>
                        <div className='col-md-4 col-12 d-flex flex-column justify-content-center align-items-center'>
                          <span>
                            <i class='fa-solid fa-plane-departure'></i>
                            {item?.return_departure_airport_code}
                          </span>
                          <p>
                            {moment(item.return_departure_time).format('LT')}
                          </p>
                          <span>
                            {moment(item.return_departure_time).format(
                              'DD-MM-YYYY'
                            )}
                          </span>
                        </div>
                        <div className='col-md-4 col-12 d-flex flex-column justify-content-center align-items-center'>
                          <span>
                            <i class='fa-solid fa-plane-arrival'></i>
                            {item?.return_arrival_airport_code}
                          </span>
                          <p>{moment(item.return_arrival_time).format('LT')}</p>
                          <span>
                            {moment(item.return_arrival_time).format(
                              'DD-MM-YYYY'
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              )}
              {/* Visa Details */}
              {invoiceDate.visa_type !== null && (
                <>
                  <div class='v-heading-icon bg-title clearfix mt-3'>
                    <div class='float-left'>
                      <img src='https://admin.umrahshop.com/public/invoice/assets/img/hotel.png' />
                    </div>
                    <div class='main-title-section float-left'>
                      <h3>Visa Details</h3>
                    </div>
                  </div>
                  <div className='mx-3'>
                    <div className='row'>
                      <div className='col-md-4 p-4 col-12 d-flex flex-column justify-content-center align-items-center'>
                        <h5>Visa Type</h5>
                        <p>{invoiceDate.visa_type}</p>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {/* Transport Details */}
              {transportDetail.length > 0 && (
                <>
                  <div class='v-heading-icon bg-title clearfix mt-3'>
                    <div class='float-left'>
                      <img src='https://admin.umrahshop.com/public/invoice/assets/img/transportation.png' />
                    </div>
                    <div class='main-title-section float-left'>
                      <h3>Transport Details</h3>
                    </div>
                  </div>
                  {transportDetail.map((item, index) => (
                    <div key={index} className='mx-3 mt-4'>
                      <div className='row'>
                        <div className='col-md-4 p-4 col-12 d-flex flex-column justify-content-center align-items-center'>
                          <p >Vehicle : {item?.transportation_vehicle_type}</p>
                        </div>
                        <div className='col-md-4 col-12 d-flex flex-column justify-content-center align-items-center'>
                          <p className='mt-0 pt-0'>Pickup Location</p>
                          <p className='mt-0 pt-0 fw-bold'>{item?.transportation_pick_up_location}</p>
                          <p>
                            Pickup Date :{' '}
                            {moment(item?.transportation_pick_up_date).format(
                              'DD-MM-YYYY'
                            )}
                          </p>
                          <p className='mt-0 pt-0'>
                            Pickup Time :{' '}
                            {moment(item?.transportation_pick_up_date).format(
                              'LT'
                            )}
                          </p>
                        </div>
                        <div className='col-md-4 col-12 d-flex flex-column justify-content-center align-items-center'>
                          <p>Dropoff Location</p>
                          <p className='mt-0 pt-0 fw-bold'>{item?.transportation_drop_off_location}</p>
                          <p className='mt-0 pt-0'>
                            Dropoff Date :{' '}
                            {moment(item?.transportation_drop_of_date).format(
                              'DD-MM-YYYY'
                            )}
                          </p>
                          <p className='mt-0 pt-0'>
                            Dropoff Time :{' '}
                            {moment(item?.transportation_drop_of_date).format(
                              'LT'
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                  {transportDetailMore.map((item, index) => (
                    <div key={index} className='mx-3 mt-4'>
                      <div className='row'>
                        <div className='col-md-4 p-4 col-12 d-flex flex-column justify-content-center align-items-center'>
                          <p>
                           More Tranfer Details
                          </p>
                        </div>
                        <div className='col-md-4 col-12 d-flex flex-column justify-content-center align-items-center'>
                          <p >Pickup Location</p>
                          <p className='mt-0 pt-0 fw-bold'>{item?.more_transportation_pick_up_location}</p>
                          <p className='mt-0 pt-0'>
                            Pickup Date :{' '}
                            {moment(
                              item?.more_transportation_pick_up_date
                            ).format('DD-MM-YYYY')}
                          </p>
                          <p className='mt-0 pt-0'>
                            Pickup Time :{' '}
                            {moment(
                              item?.more_transportation_pick_up_date
                            ).format('LT')}
                          </p>
                        </div>
                        <div className='col-md-4 col-12 d-flex flex-column justify-content-center align-items-center'>
                          <p>Dropoff Location</p>
                          <p className='mt-0 pt-0 fw-bold'>{item?.more_transportation_drop_off_location}</p>
                          <p className='mt-0 pt-0'>
                            Dropoff Date :{' '}
                            {moment(
                              item?.more_transportation_drop_of_date
                            ).format('DD-MM-YYYY')}
                          </p>
                          <p className='mt-0 pt-0'>
                            Dropoff Time :{' '}
                            {moment(
                              item?.more_transportation_drop_of_date
                            ).format('LT')}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </section>
          </div>
          <div className='col-md-4 col-sm-12'>
            <section class='vochure-detail-section p-2 cs-hide_print'>
              <div style={{ gap: '.5em' }} className='d-flex '>
                <NavLink className='w-100' to={`/invoice/${invoiceDate?.id}`}>
                <button className='btn btn-success w-100'>View Invoice</button>
                </NavLink>
                <button onClick={()=>window.print()} className='btn btn-secondary w-100'>
                  Print Voucher
                </button>
              </div>
              <button onClick={withoutcontact} style={{background:'#ae8c32'}} className='btn btn-warning w-100 mt-2'>
                Print Without Contact Details
              </button>
            </section>
            <section class='vochure-detail-section p-2 mt-4'>
              <h5 className='text-center text-black'>Lead Passenger Details</h5>
              <ul class='list-items list-items-3 list-items-4  clearfix'>
                <li class='d-block'>
                  <div class='row'>
                    <div class='col-sm-5'>
                      <span class='text-black font-weight-bold'>Name:</span>
                    </div>
                    <div class='col-sm-7'>
                      {invoiceDate?.f_name} {invoiceDate?.middle_name}
                    </div>
                  </div>
                </li>
                <li class='d-block'>
                  <div class='row'>
                    <div class='col-sm-5'>
                      <span class='text-black font-weight-bold'>
                        Phone Number:
                      </span>
                    </div>
                    <div class='col-sm-7'>{invoiceDate?.mobile}</div>
                  </div>
                </li>

                <li class='d-block'>
                  <div class='row'>
                    <div class='col-sm-5'>
                      <span class='text-black font-weight-bold'>Email:</span>
                    </div>
                    <div class='col-sm-7'>{invoiceDate?.email}</div>
                  </div>
                </li>
              </ul>
            </section>
            <section className={`vochure-detail-section p-2 mt-4 ${hideContact ? 'cs-hide_print' : ''}`}>
              <h5 className='text-center text-black'>Contact Information</h5>
              <div className='text-center'>
                <small>Feel free to contact us any time</small>
              </div>
              <ul class='list-items list-items-3 list-items-4  clearfix'>
                <li class='d-block'>
                  <p className='text-black'>
                    UMRAH SHOP 24/7 HELPLINE: +92-303-6060601
                  </p>
                </li>
                <li class='d-block'>
                  <p className='text-black'>
                    KSA OPREATION OFFICE: +966 56 468 9528
                  </p>
                </li>
              </ul>
            </section>
          </div>
        </div>
      </div>
      <div class='px-3'>
        <img style={{ width: '100%' }} src={footer} alt='letterhead' />
      </div>
    </>
  )
}
export default BookingVoucher
