import React, { useState } from "react";
import Axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import { ApiEndPoint } from "../../Components/GlobalData/GlobalData";
function Settingage(){
    var userData = JSON.parse(localStorage.getItem('agentdata'))
    const [error,seterror]=useState('')
    const baseurl = ApiEndPoint();
    const [isBooking, setIsBooking] = useState(false);
    const[paswordDetail,setPasswordDetail]=useState({oldpass:'',newpass:'',confirmpass:''});
    const handlePasswordChange = (event) => {
        const { value, name } = event.target;
        setPasswordDetail({
            ...paswordDetail,
            [name]: value,
        });
    };
    const submitpassword=async()=>{
        seterror('');
        if(paswordDetail.oldpass===''){
            seterror('Enter Current Password.');
            return;
        }else if(paswordDetail.newpass===''){
            seterror('Enter New Password.');
            return;
        }else if(paswordDetail.confirmpass===''){
            seterror('Enter Confirm Password.');
            return;
        }else if(paswordDetail.newpass!==paswordDetail.confirmpass){
            seterror('Password not match');
            return;
        }
        setIsBooking(true);
        const data = {
            'token': userData.token,
            'current_password':paswordDetail.oldpass,
            'new_password':paswordDetail.newpass,
            'cnew_password':paswordDetail.confirmpass,
            'id':userData.id,
          };
      
          try {
            const response = await Axios.post(
              baseurl + '/api/agent_change_password',
              data
            );
            setIsBooking(false);
            if(response.data.status!=='error')
                {
                    toast.success(response.data.message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    setPasswordDetail({oldpass:'',newpass:'',confirmpass:''})
                    
                }else{
                    toast.error(response.data.message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            
          } catch (error) {
            setIsBooking(false);
            console.log(error);
            console.error('Error:', error);
          }
    }
    return (
        <>
        <ToastContainer/>
        <div style={{background:'#f7f7fa'}}>
            <h3 style={{color:'#000'}} className="text-center p-3">Change Password</h3>
            <div className="hr-devider"></div>

            <div class="form-group p-4">
                <label >Current Password</label>
                <input type="password" onChange={handlePasswordChange} value={paswordDetail.oldpass} name="oldpass" class="form-control"  placeholder="Enter current password"/>
            </div>
            <div class="form-group p-4">
                <label >New Password</label>
                <input type="password" class="form-control" onChange={handlePasswordChange} value={paswordDetail.newpass} name="newpass"  placeholder="Enter new password"/>
            </div>
            <div class="form-group p-4">
                <label >Confirm New Password</label>
                <input type="password" class="form-control" onChange={handlePasswordChange} value={paswordDetail.confirmpass} name="confirmpass"  placeholder="Enter csonfirm password"/>
            </div>
            <h6 style={{color:'red'}} className="text-center">{error}</h6>
            <div className="p-4 text-end">
            <button className="btn btn-primary" onClick={submitpassword}>{isBooking ? 'Please Wait....' : 'Submit'}</button>
            </div>
        </div>
        </>
    )
}
export default Settingage;