import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import logo from '../../Images/logo.png'
import React, { useState } from 'react'
import axios from 'axios'
import moment from 'moment'
import Loading from '../../Components/Loading/Loader'
import { ApiEndPoint,Hotelapitoken } from '../../Components/GlobalData/GlobalData'
import { useNavigate } from 'react-router-dom'
import Layout from '../../Components/Layout/Layout'
function Login () {
    const baseurl=ApiEndPoint();
    const navigate=useNavigate();
    const token=Hotelapitoken();
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [error, seterror] = useState({});
  const [apiError, setApiError] = useState('');
  const [loader,setLoader]=useState(false)
  const [showHidePassword, setShowHidePassword] = useState(false)
  const handlechange = event => {
    const { name, value } = event.target
    if (name === 'username') {
      setUsername(value)
    }
    if (name === 'password') {
      setPassword(value)
    }
  }
  const handleShowHide = () => {
    setShowHidePassword(!showHidePassword)
  }
  const submitlogin =async (event) => {
    event.preventDefault();
    var validation = {}
    if (username === '') {
      validation.username = 'Please enter your username'
    }
    if (password === '') {
      validation.password = 'Please enter your password'
    }
    if (validation.username || validation.password) {
      seterror(validation)
      return
    }
    seterror({});
    const data = {
      token:token,
      email: username,
      password: password
    }
    setLoader(true);
    try {
      const response = await axios.post(
        baseurl + '/api/login_customer_submit',
        data,
        {
          headers: {
            'Access-Control-Allow-Origin': '*'
          }
        }
      )
      setLoader(false)
      if (response.data.status === 'error') {
        setApiError(response.data.message)
      } else {
        const loginTime = new Date().getTime();
        localStorage.setItem("loginTime", loginTime);
        response.data.b2b_agent.currency=response.data?.customer_Data?.currency_value;
        response.data.b2b_agent.customer_Id=response.data?.customer_Data?.id;
        localStorage.setItem('token', response.data.b2b_agent.token);
        localStorage.setItem('agentdata', JSON.stringify(response.data.b2b_agent));
        navigate("/dashboard");
      }
    } catch (error) {
      setLoader(false)
      console.error('Error:', error)
    }
  }
  return (
    <Layout>
    {loader && <Loading/>}
      <div className='authentication-bg position-relative'>
        <div class='position-absolute start-0 end-0 start-0 bottom-0 w-100 h-100'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='100%'
            height='100%'
            viewBox='0 0 800 800'
          >
            <g fillOpacity='0.22'>
              <circle
                style={{ fill: 'rgba(114, 124, 245, 0.1)' }}
                cx='400'
                cy='400'
                r='600'
              ></circle>
              <circle
                style={{ fill: 'rgba(114, 124, 245, 0.2)' }}
                cx='400'
                cy='400'
                r='500'
              ></circle>
              <circle
                style={{ fill: 'rgba(114, 124, 245, 0.3)' }}
                cx='400'
                cy='400'
                r='300'
              ></circle>
              <circle
                style={{ fill: 'rgba(114, 124, 245, 0.4)' }}
                cx='400'
                cy='400'
                r='200'
              ></circle>
              <circle
                style={{ fill: 'rgba(114, 124, 245, 0.5)' }}
                cx='400'
                cy='400'
                r='100'
              ></circle>
            </g>
          </svg>
        </div>
        <div class='account-pages pt-2 pt-sm-5 pb-4 pb-sm-5 position-relative'>
          <div class='container'>
            <div class='row justify-content-center'>
              <div class='col-xxl-4 col-lg-5'>
                <div class='card'>
                  <div class='card-header py-2 text-center bg-primary'>
                    <a href='index.html'>
                      <span>
                        <img src={logo} alt='logo' height='80' />
                      </span>
                    </a>
                  </div>

                  <div class='card-body p-4'>
                    <div class='text-center w-75 m-auto'>
                      <h4 class='text-dark-50 text-center pb-0 fw-bold'>
                        Sign In
                      </h4>
                      <p class='text-muted mb-4'>
                        Enter your email address and password to access admin
                        panel.
                      </p>
                    </div>

                    <form onSubmit={submitlogin} >
                      <div class='mb-3'>
                        <label for='emailaddress' class='form-label'>
                          Email address
                        </label>
                        <input
                          class='form-control'
                          type='email'
                          onChange={handlechange}
                          value={username}
                          name='username'
                          id='emailaddress'
                          required=''
                          placeholder='Enter your email'
                        />
                        {error.username && <p className='text-danger'>{error.username}</p>}
                      </div>

                      <div class='mb-3'>
                        <a
                          href='pages-recoverpw.html'
                          class='text-muted float-end'
                        >
                          <small>Forgot your password?</small>
                        </a>
                        <label for='password' class='form-label'>
                          Password
                        </label>
                        <div class='input-group input-group-merge'>
                          <input
                            type={`${showHidePassword ? 'text' : 'password'}`}
                            id='password'
                            onChange={handlechange}
                            value={password}
                            name='password'
                            class='form-control'
                            placeholder='Enter your password'
                          />
                          <div
                            class='input-group-text'
                            onClick={handleShowHide}
                            data-password='false'
                          >
                            <span class='password-eye'>
                              <FontAwesomeIcon
                                icon={showHidePassword ? faEyeSlash : faEye}
                              />
                            </span>
                          </div>
                        </div>
                        {error.password && <p className='text-danger'>{error.password}</p>}

                      </div>
                      <div class='mb-3 mb-0 text-center'>
                      
                      {apiError && <p className='text-danger'>{apiError}</p>}

                        <button
                          class='btn search-btn1 btn-primary'
                          type='submit'
                        >
                          {' '}
                          Log In{' '}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>

                <div class='row mt-3'>
                  <div class='col-12 text-center'>
                    <p class='text-muted'>
                      © {moment().format('YYYY')} Umrah Shop. All rights
                      reserved.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default Login
