import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import $ from 'jquery'
import 'datatables.net'
import { ApiEndPoint } from '../../Components/GlobalData/GlobalData'
import moment from 'moment'
import { NavLink } from 'react-router-dom'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import header from '../../Images/Pages/header.png'
function AccountStatement () {
  const baseurl = ApiEndPoint()
  const tableRef = useRef(null)
  const UserDetail = JSON.parse(localStorage.getItem('agentdata'))
  const [bookingDetails, setBookingDetails] = useState([])
  const [totalCredit, setTotalCredit] = useState(0)
  const [totalDebit, setTotalDebit] = useState(0)
  useEffect(() => {
    GetStatement()
  }, [])
  useEffect(() => {
    // Initialize DataTables only after bookingDetails is updated and not yet initialized
    if (
      bookingDetails.length > 0 &&
      tableRef.current &&
      !$.fn.dataTable.isDataTable('#accountStatement')
    ) {
      $('#accountStatement').DataTable({
        scrollX: true
      })
    }
  }, [bookingDetails])

  const GetStatement = async () => {
    var data = {
      agent_id: UserDetail.id,
      customer_id: UserDetail?.customer_Id
    }
    try {
      const response = await axios.post(
        baseurl + '/api/b2b_Agents_Booking_Statement',
        data
      )
      if (response.data.status === 'success') {
        const agentsArray = Object.values(response.data.Agents_detail)
        var newarr = agentsArray.filter(item => !item.pay_make_id)
        // console.log(newarr)
        const creditamount = newarr.reduce((total, row) => {
          return total + (row?.Amount ? Number(row.Amount) : 0)
        }, 0)
        const debitamount = newarr.reduce((total, row) => {
          return (
            total +
            (row?.total_sale_price_all_Services
              ? Number(row.total_sale_price_all_Services)
              : 0)
          )
        }, 0)

        setTotalCredit(creditamount)
        setTotalDebit(debitamount)
        setBookingDetails(newarr)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const exportToPDF = () => {
    const doc = new jsPDF()
    doc.addImage(header, 'PNG', 10, 10, 190, 40)
    const tableData = bookingDetails.map((row, index) => {
      let serviceTypeString = ''
      if (row?.pay_recv_id) {
        serviceTypeString = 'Payment Received Id:' + row?.pay_recv_id
      } else {
        const service = JSON.parse(row?.services)
        if (service && Array.isArray(service)) {
          service.forEach(service => {
            if (service === '1') {
              serviceTypeString = 'All Services'
            } else if (service === 'accomodation_tab') {
              serviceTypeString += 'Hotel, '
            } else if (service === 'transportation_tab') {
              serviceTypeString += 'Transfer, '
            } else if (service === 'flights_tab') {
              serviceTypeString += 'Flight, '
            } else if (service === 'visa_tab') {
              serviceTypeString += 'Visa, '
            }
          })
          serviceTypeString = serviceTypeString.replace(/, $/, '')
        }
      }

      return [
        index + 1,
        serviceTypeString,
        row?.remarks || `Invoice Id: ${row?.id}`,
        moment(row?.check_in).format('DD-MM-YYYY'),
        row?.total_sale_price_all_Services
          ? UserDetail?.currency + ' ' + row?.total_sale_price_all_Services
          : '',
        row?.Amount ? UserDetail?.currency + ' ' + row?.Amount : '',
        row?.f_name,
        moment(row?.created_at).format('DD-MM-YYYY')
      ]
    })

    // Add table header
    const tableHeaders = [
      'Sr',
      'Type',
      'Description',
      'Check In',
      'Debit',
      'Credit',
      'Guest Name',
      'Date'
    ]

    // Add title
    doc.text('Account Statement', 14, 53)
    const agentName = `Agent Name: ${UserDetail?.first_name} ${UserDetail?.last_name}`
    const companyName = `Company Name: ${UserDetail?.company_name}`
    const pageWidth = doc.internal.pageSize.width
    const rightMargin = 10 // Add margin to the right edge of the page

    // Calculate the x positions for Agent and Company names
    const agentNameWidth = doc.getTextWidth(agentName)
    const companyNameWidth = doc.getTextWidth(companyName)

    // Right-align text by subtracting the text width from the total page width
    const xPositionAgent = pageWidth - rightMargin - agentNameWidth
    const xPositionCompany = pageWidth - companyNameWidth

    // Add Agent and Company names at the top-right
    doc.setFontSize(10)
    doc.text(companyName, xPositionCompany, 55)
    doc.text(agentName, xPositionAgent, 60)
    // Add table
    doc.autoTable({
      head: [tableHeaders],
      body: tableData,
      startY: 65
    })

    // Add footer for totals
    doc.setFontSize(11)

    // Prepare text for debit, credit, and balance
    const debit = `Total Debit: ${UserDetail?.currency} ${Number(
      totalDebit
    ).toFixed(2)}`
    const credit = `Total Credit: ${UserDetail?.currency} ${Number(
      totalCredit
    ).toFixed(2)}`
    const balance = `Balance: ${UserDetail?.currency} ${(
      Number(totalDebit) - Number(totalCredit)
    ).toFixed(2)}`

    // Calculate the page width
    const pageWidthn = doc.internal.pageSize.width
    const rightMarginn = 15 // Add margin to the right edge of the page

    // Calculate the width of the debit and credit text
    const debitWidth = doc.getTextWidth(debit)
    const creditWidth = doc.getTextWidth(credit)

    // Calculate the x positions for right-aligned texts
    const xPositionDebit = pageWidthn - rightMarginn - debitWidth
    const xPositionCredit = pageWidthn - rightMarginn - creditWidth

    // Get the last position of the auto table
    const yPosition = doc.lastAutoTable.finalY + 10 // Adjust the Y-position as needed

    // Add the texts at the right side of the page
    doc.text(debit, xPositionDebit, yPosition)
    doc.text(credit, xPositionCredit, yPosition + 5) // Adjust Y-position for credit
    doc.text(balance, 14, doc.lastAutoTable.finalY + 10) // Adjust Y-position for balance

    // Save PDF
    doc.save('account_statement.pdf')
  }
  return (
    <div>
      <div className='text-end'>
        <button className='btn btn-sm btn-primary mt-2 ' onClick={exportToPDF}>
          Download PDF
        </button>
      </div>
      <table
        key='AccountTable'
        id='accountStatement'
        className='display nowrap'
        ref={tableRef}
        style={{ width: '100%' }}
      >
        <thead>
          <tr>
            <th>Sr</th>
            <th>Type</th>
            <th>Description</th>
            <th>Check In</th>
            <th>Debit</th>
            <th>Credit</th>
            <th>Guest Name</th>
            <th>Date</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {bookingDetails.map((row, index) => {
            let serviceTypeString = ''
            if (row?.pay_recv_id) {
              serviceTypeString = 'Payment Received Id:' + row?.pay_recv_id
            } else {
              const service = JSON.parse(row?.services)
              if (service && Array.isArray(service)) {
                service.forEach(service => {
                  if (service === '1') {
                    serviceTypeString = 'All Services'
                  } else if (service === 'accomodation_tab') {
                    serviceTypeString += 'Hotel, '
                  } else if (service === 'transportation_tab') {
                    serviceTypeString += 'Transfer, '
                  } else if (service === 'flights_tab') {
                    serviceTypeString += 'Flight, '
                  } else if (service === 'visa_tab') {
                    serviceTypeString += 'Visa, '
                  }
                })
                serviceTypeString = serviceTypeString.replace(/, $/, '')
              }
            }
            let accomodation = []
            let accomodationmore = []
            let DepartureFlight = []
            let transferDetail = []
            if (
              row?.accomodation_details &&
              row?.accomodation_details !== null &&
              row?.accomodation_details !== '' &&
              row?.accomodation_details !== 'null'
            ) {
              accomodation = JSON.parse(row?.accomodation_details)
            }
            if (
              row?.accomodation_details_more &&
              row?.accomodation_details_more !== null &&
              row?.accomodation_details_more !== '' &&
              row?.accomodation_details_more !== 'null'
            ) {
              accomodationmore = JSON.parse(row?.accomodation_details_more)
            }
            if (row?.flights_details && row?.flights_details !== null) {
              DepartureFlight = JSON.parse(row?.flights_details)
            }
            if (
              row?.transportation_details &&
              row?.transportation_details !== null
            ) {
              const transferlist = JSON.parse(row?.transportation_details)
              var newtransferlist = transferlist.filter(
                item => item.transportation_pick_up_location !== ''
              )
              transferDetail = newtransferlist
            }

            return (
              <tr
                key={index}
                className={`${row?.pay_recv_id ? 'payment_row' : ''}`}
              >
                <td>{index + 1}</td>
                <td>{serviceTypeString}</td>
                {row?.pay_recv_id ? (
                  <td>{row?.remarks}</td>
                ) : (
                  <td>
                    <span className='text-success'>Invoice Id: {row?.id}</span>
                    {accomodation.length > 0 && (
                      <div>
                        <p className='fw-bold text-center'>
                          Accomodation Details
                        </p>
                        {accomodation.map((acc, index) => (
                          <div key={index} className='small border-bottom'>
                            {acc?.acc_qty} X {acc?.hotel_type_cat}:{' '}
                            {acc?.acc_hotel_name} ({acc?.hotel_city_name})
                            <br />
                            {moment(acc?.acc_check_in).format(
                              'DD/MM/YYYY'
                            )}{' '}
                            <i className='fa-solid fa-arrow-right'></i>{' '}
                            {moment(acc?.acc_check_out).format('DD/MM/YYYY')}
                          </div>
                        ))}
                        {accomodationmore.map((acc, index) => (
                          <div key={index} className='small border-bottom mt-2'>
                            {acc?.more_acc_qty} X {acc?.more_hotel_type_cat}:{' '}
                            {acc?.more_acc_hotel_name} ({acc?.more_hotel_city})
                            <br />
                            {moment(acc?.more_acc_check_in).format(
                              'DD/MM/YYYY'
                            )}{' '}
                            <i className='fa-solid fa-arrow-right'></i>{' '}
                            {moment(acc?.more_acc_check_out).format(
                              'DD/MM/YYYY'
                            )}
                          </div>
                        ))}
                      </div>
                    )}
                    {DepartureFlight.length > 0 && (
                      <div>
                        <p className='fw-bold text-center mt-2'>
                          Flight Departure Details
                        </p>
                        {DepartureFlight.map((flight, index) => (
                          <div key={index} className='small border-bottom'>
                            <i className='fa-solid fa-plane'></i>{' '}
                            {flight?.other_Airline_Name2} (
                            {flight?.departure_flight_number})
                            <br />
                            <i className='fa-solid fa-plane-departure'>
                              {' '}
                            </i>{' '}
                            {flight?.departure_airport_code}
                            <br />
                            <i className='fa-solid fa-plane-arrival'></i>{' '}
                            {flight?.arrival_airport_code}
                            <br />
                            <i className='fa-solid fa-calendar'></i>{' '}
                            {moment(flight?.departure_time).format(
                              'DD/MM/YYYY'
                            )}{' '}
                            | <i className='fa-solid fa-calendar'></i>{' '}
                            {moment(flight?.arrival_time).format('DD/MM/YYYY')}
                          </div>
                        ))}
                      </div>
                    )}
                    {transferDetail.length > 0 && (
                      <div>
                        <p className='fw-bold text-center mt-2'>
                          Transfer Details
                        </p>
                        {transferDetail.map((transf, index) => (
                          <div key={index} className='small border-bottom'>
                            <i className='fa-solid fa-car'></i>{' '}
                            {transf?.transportation_pick_up_location}
                            <br />
                            <i className='fa-solid fa-building'> </i>{' '}
                            {transf?.transportation_drop_off_location}
                            <br />
                            <i className='fa-solid fa-calendar'></i>{' '}
                            {moment(transf?.transportation_pick_up_date).format(
                              'DD/MM/YYYY'
                            )}{' '}
                            | <i className='fa-solid fa-calendar'></i>{' '}
                            {moment(transf?.transportation_drop_of_date).format(
                              'DD/MM/YYYY'
                            )}
                          </div>
                        ))}
                      </div>
                    )}
                    {row?.visa_type &&
                      row?.visa_Pax &&
                      row?.visa_type !== null &&
                      row?.visa_Pax !== null && (
                        <div>
                          <div className='small mt-2'>
                            <i class='fa-brands fa-cc-visa'></i> {row?.visa_Pax}{' '}
                            X {row?.visa_type}
                          </div>
                        </div>
                      )}
                  </td>
                )}
                <td>{moment(row?.check_in).format('DD-MM-YYYY')}</td>
                <td>
                  {row?.total_sale_price_all_Services
                    ? UserDetail?.currency +
                      ' ' +
                      row?.total_sale_price_all_Services
                    : ''}
                </td>
                <td>
                  {row?.Amount ? UserDetail?.currency + ' ' + row?.Amount : ''}{' '}
                </td>
                <td>{row?.f_name}</td>
                <td>{moment(row?.created_at).format('DD-MM-YYYY')}</td>
                <td>
                  {row?.total_sale_price_all_Services && (
                    <div>
                      <NavLink target='blank' to={`/invoice/${row.id}`}>
                        <button
                          type='submit'
                          className='btn btn-primary my-1 btn-sm w-100 small'
                        >
                          Invoice
                        </button>
                      </NavLink>
                      <br />
                      <NavLink target='blank' to={`/voucher/${row.id}`}>
                        <button
                          type='submit'
                          className='btn btn-success  w-100 my-1 btn-sm small'
                        >
                          Voucher
                        </button>
                      </NavLink>
                    </div>
                  )}
                </td>
              </tr>
            )
          })}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan='4' className='fw-bold'>
              Balance: {UserDetail?.currency} {Number(totalDebit)-Number(totalCredit)}
            </td>
            <td
              style={{ width: '6em' }}
              className='bg-success text-center text-white fw-bold'
            >
              {UserDetail?.currency} {Number(totalDebit).toFixed(2)}
            </td>
            <td
              style={{ width: '6em' }}
              className='bg-success text-center text-white fw-bold'
            >
              {UserDetail?.currency} {Number(totalCredit).toFixed(2)}
            </td>
            <td colSpan='3'></td>
          </tr>
        </tfoot>
      </table>
    </div>
  )
}
export default AccountStatement
