import React, { useEffect, useState } from 'react'
import $ from 'jquery'
import 'datatables.net'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import header from '../../Images/Pages/header.png'

function ArrivalList ({ BookingList }) {
  const [BookingsData, setBookingsData] = useState([])
  const [defaultList, setDefaultList] = useState([])
  const [FilterOption, setFilterOption] = useState('')
  const [FilterBy, setFilterBy] = useState('')
  const [customDateFrom, setCustomDateFrom] = useState('')
  const [customDateTo, setCustomDateTo] = useState('')
  const [isSubmit, setIsSubmit] = useState(false)
  var UserDetail = JSON.parse(localStorage.getItem('agentdata'))
  useEffect(() => {
    if (BookingsData.length > 0) {
      $(document).ready(function () {
        $('#ArrivalList').DataTable()
      })
    }
  }, [BookingsData])

  useEffect(() => {
    if (BookingList !== null) {
      const newList = BookingList.filter(item => {
        if (item.domain_name === 'Hotel Booking') {
          const reservation = JSON.parse(item.reservation_response)
          const checkinDate = new Date(reservation.hotel_details.checkIn) // Assuming the date is in a valid format
          const today = new Date()
          // Compare dates: only return items where the check-in date is in the future
          return checkinDate > today
        }
        if (item.domain_name === 'Transfer Booking') {
          const today = new Date()
          // Check for transfer bookings with a departure date after today
          const departureDate = new Date(item.departure_date) // Assuming `departure_date` is a string in the item
          return departureDate > today
        }
        if (item.domain_name === 'Inovice Booking') {
          const today = new Date()
          var acco = []
          var accomore = []
          var transfer = []
          var transfermore = []
          // Check for transfer bookings with a departure date after today
          if (
            item.accomodation_details !== 'null' &&
            item.accomodation_details !== null
          ) {
            acco = JSON.parse(item.accomodation_details)
            return acco.some(detail => {
              const checkInDate = new Date(detail.acc_check_in) // Parse each acc_check_in date
              return checkInDate > today // Compare the date
            })
          }
          if (
            item.accomodation_details_more !== 'null' &&
            item.accomodation_details_more !== null
          ) {
            accomore = JSON.parse(item.accomodation_details_more)
            return accomore.some(detail => {
              const checkInDate = new Date(detail.more_acc_check_in) // Parse each acc_check_in date
              return checkInDate > today // Compare the date
            })
          }
          if (
            item.transportation_details !== 'null' &&
            item.transportation_details !== null
          ) {
            transfer = JSON.parse(item.transportation_details)
            const newTransfer = transfer.filter(
              transfer => transfer.transportation_pick_up_location !== ''
            )
            return newTransfer.some(detail => {
              const checkInDate = new Date(detail.transportation_pick_up_date) // Parse each acc_check_in date
              return checkInDate > today // Compare the date
            })
          }
          if (
            item.transportation_details_more !== 'null' &&
            item.transportation_details_more !== null
          ) {
            transfermore = JSON.parse(item.transportation_details_more)
            return transfermore.some(detail => {
              const checkInDate = new Date(
                detail.more_transportation_pick_up_date
              ) // Parse each acc_check_in date
              return checkInDate > today // Compare the date
            })
          }
        }
        return false // Exclude items that are not hotel or transfer bookings
      })
      setBookingsData(newList)
      setDefaultList(newList)
    }
  }, [BookingList])

  const handlefilteroption = event => {
    event.preventDefault();
    event.stopPropagation();
    setIsSubmit(false)
    setFilterOption(event.target.value)
    if (event.target.value === '' && event.target.value === 'all') {
      setFilterBy('')
    }
  }
  const handlefilterBy = event => {
    event.preventDefault();
    event.stopPropagation();
    setIsSubmit(false)
    setFilterBy(event.target.value)
  }
  const handleFilterSubmit = () => {
    if (FilterOption === '') {
      return
    }
    setIsSubmit(true)
    if (FilterOption === 'all') {
      $('#ArrivalList').DataTable().destroy();
      setBookingsData(defaultList)
      return
    }
    const FilterList = defaultList.filter(item => {
      if (item.domain_name === 'Hotel Booking') {
        const reservation = JSON.parse(item.reservation_response)
        const checkinDate = moment(reservation.hotel_details.checkIn)
        const checkOutDate = moment(reservation.hotel_details.checkOut)
        // Assuming the date is in a valid format
        // Compare dates: only return items where the check-in date is in the future
        if (FilterOption === 'today') {
          if (FilterBy === 'checkin' || FilterBy === '') {
            return checkinDate.isSame(moment(), 'day')
          } else if (FilterBy === 'checkout') {
            return checkOutDate.isSame(moment(), 'day')
          }
        } else if (FilterOption === 'tomorrow') {
          if (FilterBy === 'checkin' || FilterBy === '') {
            return checkinDate.isSame(moment().add(1, 'day'), 'day')
          } else if (FilterBy === 'checkout') {
            return checkOutDate.isSame(moment().add(1, 'day'), 'day')
          }
        } else if (FilterOption === 'week') {
          if (FilterBy === 'checkin' || FilterBy === '') {
            return checkinDate.isSame(moment(), 'week')
          } else if (FilterBy === 'checkout') {
            return checkOutDate.isSame(moment(), 'week')
          }
        } else if (FilterOption === 'month') {
          if (FilterBy === 'checkin' || FilterBy === '') {
            return checkinDate.isSame(moment(), 'month')
          } else if (FilterBy === 'checkout') {
            return checkOutDate.isSame(moment(), 'month')
          }
        } else if (FilterOption === 'custom') {
          const datefrom = moment(customDateFrom)
          const dateto = moment(customDateTo)
          if (FilterBy === 'checkin' || FilterBy === '') {
            return checkinDate.isBetween(datefrom, dateto, 'day', '[]')
          } else if (FilterBy === 'checkout') {
            return checkOutDate.isBetween(datefrom, dateto, 'day', '[]')
          }
        }
      }
      if (item.domain_name === 'Transfer Booking') {
        // Check for transfer bookings with a departure date after today
        const departureDate = moment(item.departure_date) // Assuming `departure_date` is a string in the item
        if (FilterOption === 'today') {
          return departureDate.isSame(moment(), 'day')
        } else if (FilterOption === 'tomorrow') {
          return departureDate.isSame(moment().add(1, 'day'), 'day')
        } else if (FilterOption === 'week') {
          return departureDate.isSame(moment(), 'week')
        } else if (FilterOption === 'month') {
          return departureDate.isSame(moment(), 'month')
        } else if (FilterOption === 'custom') {
          const datefrom = moment(customDateFrom)
          const dateto = moment(customDateTo)
          return departureDate.isBetween(datefrom, dateto, 'day', '[]')
        }
      }
      if (item.domain_name === 'Inovice Booking') {
        // Invoice Booking
        let accomodation = []
        let accomodationmore = []
        let DepartureFlight = []
        let transferDetail = []
        if (
          item?.accomodation_details &&item?.accomodation_details !== null &&item?.accomodation_details !== '' &&item?.accomodation_details !== 'null'
        ) {
          accomodation = JSON.parse(item?.accomodation_details)
        }
        if (
          item?.accomodation_details_more &&
          item?.accomodation_details_more !== null &&
          item?.accomodation_details_more !== '' &&
          item?.accomodation_details_more !== 'null'
        ) {
          accomodationmore = JSON.parse(
            item?.accomodation_details_more
          )
        }
        if (
          item?.flights_details &&
          item?.flights_details !== null
        ) {
          DepartureFlight = JSON.parse(item?.flights_details)
        }
        if (
          item?.transportation_details &&
          item?.transportation_details !== null
        ) {
          const transferlist = JSON.parse(
            item?.transportation_details
          )
          var newtransferlist = transferlist.filter(
            item => item.transportation_pick_up_location !== ''
          )
          transferDetail = newtransferlist
        }
        
        if (FilterOption === 'today') {
          const isacco= accomodation.some(detail => {
            const checkInDate = moment(detail.acc_check_in) // Parse each acc_check_in date
            const checkOutDate = moment(detail.acc_check_out)
            if (FilterBy === 'checkin' || FilterBy === '') {
            return checkInDate.isSame(moment(), 'day') // Compare the date
            }else{
              return checkOutDate.isSame(moment(), 'day') // Compare the date

            }
          })
          const isaccomore= accomodationmore.some(detail => {
            const checkInDate = moment(detail.more_acc_check_in) // Parse each acc_check_in date
            const checkOutDate = moment(detail.more_acc_check_out)
            if (FilterBy === 'checkin' || FilterBy === '') {
            return checkInDate.isSame(moment(), 'day') // Compare the date
            }else{
              return checkOutDate.isSame(moment(), 'day') // Compare the date

            }
          })
          const isflight= DepartureFlight.some(detail => {
            const checkInDate = moment(detail.departure_time) // Parse each acc_check_in date
            return checkInDate.isSame(moment(), 'day') // Compare the date
          })
          const istransfer= transferDetail.some(detail => {
            const checkInDate = moment(detail.transportation_pick_up_date) // Parse each acc_check_in date
            return checkInDate.isSame(moment(), 'day') // Compare the date
          })
          return isacco || isaccomore || isflight || istransfer
        } else if (FilterOption === 'tomorrow') {
          const isacco= accomodation.some(detail => {
            const checkInDate = moment(detail.acc_check_in) // Parse each acc_check_in date
            const checkOutDate = moment(detail.acc_check_out)
            if (FilterBy === 'checkin' || FilterBy === '') {
            return checkInDate.isSame(moment().add(1, 'day'), 'day') // Compare the date
            }else{
              return checkOutDate.isSame(moment().add(1, 'day'), 'day') // Compare the date

            }
          })
          const isaccomore= accomodationmore.some(detail => {
            const checkInDate = moment(detail.more_acc_check_in) // Parse each acc_check_in date
            const checkOutDate = moment(detail.more_acc_check_out)
            if (FilterBy === 'checkin' || FilterBy === '') {
            return checkInDate.isSame(moment().add(1, 'day'), 'day') // Compare the date
            }else{
              return checkOutDate.isSame(moment().add(1, 'day'), 'day') // Compare the date

            }
          })
          const isflight= DepartureFlight.some(detail => {
            const checkInDate = moment(detail.departure_time) // Parse each acc_check_in date
            return checkInDate.isSame(moment().add(1, 'day'), 'day') // Compare the date
          })
          const istransfer= transferDetail.some(detail => {
            const checkInDate = moment(detail.transportation_pick_up_date) // Parse each acc_check_in date
            return checkInDate.isSame(moment().add(1, 'day'), 'day') // Compare the date
          })
          return isacco || isaccomore || isflight || istransfer
        } else if (FilterOption === 'week') {
          const isacco= accomodation.some(detail => {
            const checkInDate = moment(detail.acc_check_in) // Parse each acc_check_in date
           
            const checkOutDate = moment(detail.acc_check_out)
            if (FilterBy === 'checkin' || FilterBy === '') {
            return checkInDate.isSame(moment(), 'week') // Compare the date
            }else{
              return checkOutDate.isSame(moment(), 'week') // Compare the date

            }
          })
          const isaccomore= accomodationmore.some(detail => {
            const checkInDate = moment(detail.more_acc_check_in) // Parse each acc_check_in date
            const checkOutDate = moment(detail.more_acc_check_out)
            if (FilterBy === 'checkin' || FilterBy === '') {
            return checkInDate.isSame(moment(), 'week') // Compare the date
            }else{
              return checkOutDate.isSame(moment(), 'week') // Compare the date

            }
          })
          const isflight= DepartureFlight.some(detail => {
            const checkInDate = moment(detail.departure_time) // Parse each acc_check_in date
            return checkInDate.isSame(moment(), 'week') // Compare the date
          })
          const istransfer= transferDetail.some(detail => {
            const checkInDate = moment(detail.transportation_pick_up_date) // Parse each acc_check_in date
            return checkInDate.isSame(moment(), 'week') // Compare the date
          })
          return isacco || isaccomore || isflight || istransfer
        } else if (FilterOption === 'month') {
          const isacco= accomodation.some(detail => {
            const checkInDate = moment(detail.acc_check_in) // Parse each acc_check_in date
            const checkOutDate = moment(detail.acc_check_out)
            if (FilterBy === 'checkin' || FilterBy === '') {
            return checkInDate.isSame(moment(), 'month') // Compare the date
            }else{
              return checkOutDate.isSame(moment(), 'month') // Compare the date

            }
          })
          const isaccomore= accomodationmore.some(detail => {
            const checkInDate = moment(detail.more_acc_check_in) // Parse each acc_check_in date
            const checkOutDate = moment(detail.more_acc_check_out)
            if (FilterBy === 'checkin' || FilterBy === '') {
            return checkInDate.isSame(moment(), 'month') // Compare the date
            }else{
              return checkOutDate.isSame(moment(), 'month') // Compare the date

            }
          })
          const isflight= DepartureFlight.some(detail => {
            const checkInDate = moment(detail.departure_time) // Parse each acc_check_in date
            return checkInDate.isSame(moment(), 'month') // Compare the date
          })
          const istransfer= transferDetail.some(detail => {
            const checkInDate = moment(detail.transportation_pick_up_date) // Parse each acc_check_in date
            return checkInDate.isSame(moment(), 'month') // Compare the date
          })
          return isacco || isaccomore || isflight || istransfer
        } else if (FilterOption === 'custom') {
          const datefrom = moment(customDateFrom)
          const dateto = moment(customDateTo)
          const isacco= accomodation.some(detail => {
            const checkInDate = moment(detail.acc_check_in) // Parse each acc_check_in date
            const checkOutDate = moment(detail.acc_check_out)
            if (FilterBy === 'checkin' || FilterBy === '') {
            return checkInDate.isBetween(datefrom, dateto, 'day', '[]') // Compare the date
            }else{
              return checkOutDate.isBetween(datefrom, dateto, 'day', '[]') // Compare the date
            }
          })
          const isaccomore= accomodationmore.some(detail => {
            const checkInDate = moment(detail.more_acc_check_in) // Parse each acc_check_in date
            const checkOutDate = moment(detail.more_acc_check_out)
            if (FilterBy === 'checkin' || FilterBy === '') {
            return checkInDate.isBetween(datefrom, dateto, 'day', '[]') // Compare the date
            }else{
              return checkOutDate.isBetween(datefrom, dateto, 'day', '[]') // Compare the date
            }
          })
          const isflight= DepartureFlight.some(detail => {
            const checkInDate = moment(detail.departure_time) // Parse each acc_check_in date
            return checkInDate.isBetween(datefrom, dateto, 'day', '[]') // Compare the date
          })
          const istransfer= transferDetail.some(detail => {
            const checkInDate = moment(detail.transportation_pick_up_date) // Parse each acc_check_in date
            return checkInDate.isBetween(datefrom, dateto, 'day', '[]') // Compare the date
          })
          return isacco || isaccomore || isflight || istransfer
        }
      }
      return false // Exclude items that are not hotel or transfer bookings
    })
    $('#ArrivalList').DataTable().destroy(); // Destroy the DataTable instance
    // $('#ArrivalList').empty(); 
    setBookingsData(FilterList)
   
  }
  const handleDateChange = event => {
    event.preventDefault();
    event.stopPropagation();
    setIsSubmit(false)
    if (event.target.name === 'from') {
      setCustomDateFrom(event.target.value)
    } else {
      setCustomDateTo(event.target.value)
    }
  }
  const exportToPDF = () => {
    const doc = new jsPDF() // Landscape orientation
    doc.addImage(header, 'PNG', 10, 10, 190, 40)
    // Define table headers
    const headers = [
      ['#', 'Description', 'Hotel Name', 'Additional Meal', 'Status/Payment']
    ]

    // Map data for rows
    const rows = BookingsData.map((item, index) => {
      let description, hotelName, additionalMeal, statusPayment, action

      if (item.domain_name === 'Hotel Booking') {
        const reservation = JSON.parse(item.reservation_response)
        const reservationreq = JSON.parse(item.reservation_request)
        const meallist = reservationreq?.meal_Details
          ? JSON.parse(reservationreq.meal_Details)
          : []

        // Description
        description = `Invoice No: ${item.invoice_no}\nHCN No: ${item.hcn_Number}\nGuest Name: ${item.lead_passenger}`

        // Hotel Name
        hotelName = `${
          reservation.hotel_details.hotel_name
        }\nCheck-In: ${moment(reservation.hotel_details.checkIn).format(
          'll'
        )} - Check-Out: ${moment(reservation.hotel_details.checkOut).format(
          'll'
        )}`

        // Additional Meal
        additionalMeal = meallist
          .map((meal, index) => `${index + 1}. ${meal.name}`)
          .join('\n')

        // Status/Payment
        const payment =
          item.payment_details && JSON.parse(item.payment_details) !== ''
            ? 'Full Payment'
            : 'No Payment'
        statusPayment = `${item.status}\n${payment}`
      } else if (item.domain_name === 'Transfer Booking') {
        // Description
        description = `Invoice No: ${item.invoice_no}\nTraveller Name: ${item.lead_passenger}`

        // Hotel Name
        hotelName = `Departure Date: ${moment(item.departure_date).format(
          'DD-MM-YYYY'
        )}`

        // Additional Meal (empty for transfer bookings)
        additionalMeal = '-'

        // Status/Payment
        statusPayment = `${item.booking_status}`
      }

      return [index + 1, description, hotelName, additionalMeal, statusPayment]
    })

    // Generate the table using autoTable
    doc.autoTable({
      head: headers,
      body: rows,
      startY: 65,
      styles: {
        fontSize: 10,
        cellPadding: 3,
        overflow: 'linebreak'
      },
      columnStyles: {
        0: { cellWidth: 15 },
        1: { cellWidth: 50 },
        2: { cellWidth: 50 },
        3: { cellWidth: 30 },
        4: { cellWidth: 40 },
        5: { cellWidth: 40 }
      },
      didDrawPage: data => {
        doc.text('Arrival List', data.settings.margin.left, 53) // Title

        const agentName = `Agent Name: ${UserDetail?.first_name} ${UserDetail?.last_name}`
        const companyName = `Company Name: ${UserDetail?.company_name}`

        // Calculate x positions based on the page width
        const pageWidth = doc.internal.pageSize.width
        const rightMargin = 10 // Add margin to the right edge of the page

        // Calculate the x positions for Agent and Company names
        const agentNameWidth = doc.getTextWidth(agentName)
        const companyNameWidth = doc.getTextWidth(companyName)

        // Right-align text by subtracting the text width from the total page width
        const xPositionAgent = pageWidth - rightMargin - agentNameWidth
        const xPositionCompany = pageWidth - companyNameWidth

        // Add Agent and Company names at the top-right
        doc.setFontSize(10)
        doc.text(companyName, xPositionCompany, 55)
        doc.text(agentName, xPositionAgent, 60) // Adjust y-position if needed
        // Adjust y-position if needed
      }
    })

    doc.save('ArrivalList.pdf')
  }
  return (
    <div className='text-black'>
      <div class='row'>
        <div class='col-12'>
          <div class='card border rounded-3'>
            <div class='card-header border-bottom'>
              <div class='d-sm-flex justify-content-between align-items-center'>
                <h5 class='mb-2 mb-sm-0'>Arrival List</h5>
                {/* <a href="#" class="btn btn-sm btn-primary mb-0">View All</a> */}
              </div>
            </div>

            <div class='card-body'>
              <div class='table-responsive border-0'>
                <div className='text-end'>
                  <button
                    onClick={exportToPDF}
                    className='btn btn-sm btn-primary mt-2 '
                  >
                    Download PDF
                  </button>
                </div>
                <div className='p-1 row'>
                  <div className='col-md-4 mt-1'>
                    <select
                      onChange={handlefilteroption}
                      value={FilterOption}
                      className=' form-select'
                    >
                      <option value=''>Select Filter</option>
                      <option value='all'>All Dates</option>
                      <option value='today'>Today</option>
                      <option value='tomorrow'>Tomorrow</option>
                      <option value='week'>This Week</option>
                      <option value='month'>This Month</option>
                      <option value='custom'>Custom Dates</option>
                    </select>
                  </div>
                  {FilterOption !== '' && FilterOption !== 'all' && (
                    <div className='col-md-4 mt-1'>
                      <select
                        onChange={handlefilterBy}
                        value={FilterBy}
                        className=' form-select'
                      >
                        <option value=''>Filter By</option>
                        <option value='checkin'>Check In</option>
                        <option value='checkout'>Check Out</option>
                      </select>
                    </div>
                  )}
                  {FilterOption === 'custom' && (
                    <>
                      <div className='col-md-4 mt-1'>
                        <input
                          type='date'
                          onChange={handleDateChange}
                          name='from'
                          value={customDateFrom}
                          className='form-control w-100'
                        />
                      </div>
                      <div className='col-md-4 mt-1'>
                        <input
                          type='date'
                          name='to'
                          onChange={handleDateChange}
                          value={customDateTo}
                          className='form-control w-100'
                        />
                      </div>
                    </>
                  )}
                  <div className='col-md-3 mt-1'>
                    <button
                      onClick={handleFilterSubmit}
                      className='btn btn-primary'
                    >
                      Filter
                    </button>
                  </div>
                </div>
                <table
                  key='AccountTable'
                  id='ArrivalList'
                  style={{ width: '100%' }}
                  class='table align-middle p-4 mb-0 table-hover table-shrink'
                >
                  <thead class='table-light'>
                    <tr>
                      <th scope='col' class='border-0 rounded-start'>
                        #
                      </th>
                      <th scope='col' class='border-0'>
                        Description
                      </th>
                      <th scope='col' class='border-0'>
                        Hotel Name
                      </th>
                      <th scope='col' class='border-0'>
                        Additional Meal
                      </th>
                      <th scope='col' class='border-0'>
                        Status/Payment
                      </th>
                      <th scope='col' class='border-0'>
                        Action
                      </th>
                    </tr>
                  </thead>

                  <tbody class='border-top-0'>
                    {BookingsData.length>0 && BookingsData.map((item, index) => {
                      let payment
                      let reservation
                      let reservationreq
                      if (item.domain_name === 'Hotel Booking') {
                        reservation = JSON.parse(item.reservation_response)
                        reservationreq = JSON.parse(item.reservation_request)
                        var meallist = []
                        if (
                          reservationreq?.meal_Details &&
                          reservationreq.meal_Details !== undefined
                        ) {
                          meallist = JSON.parse(reservationreq?.meal_Details)
                        }
                        if (item.payment_details !== null) {
                          if (JSON.parse(item.payment_details) !== '') {
                            payment = 'Full Payment'
                          } else {
                            payment = 'No Payment'
                          }
                        } else {
                          payment = 'No Payment'
                        }
                      }
                      // Invoice Booking
                      let accomodation = []
                      let accomodationmore = []
                      let DepartureFlight = []
                      let transferDetail = []
                      if (item.domain_name === 'Inovice Booking') {
                        if (
                          item?.accomodation_details &&
                          item?.accomodation_details !== null &&
                          item?.accomodation_details !== '' &&
                          item?.accomodation_details !== 'null'
                        ) {
                          const hotelList = JSON.parse(item?.accomodation_details)
                          if(isSubmit && FilterOption !== '' && FilterOption !== 'all'  && FilterBy !== ''){
                            var todate=moment(customDateTo);
                            var fromdate=moment(customDateFrom);
                            if(FilterBy==='checkin' && FilterOption==='custom' && customDateFrom !== ''){
                              accomodation = hotelList.filter(item => moment(item.acc_check_in).isBetween(fromdate, todate, 'day', '[]'))
                            }else if(FilterBy==='checkout'  && FilterOption==='custom' && customDateTo !== ''){

                              accomodation = hotelList.filter(item => moment(item.acc_check_out).isBetween(fromdate, todate, 'day', '[]'))
                            }else if(FilterOption==='today'){
                              if(FilterBy==='checkin'){
                              accomodation = hotelList.filter(item => moment(item.acc_check_in).isSame(moment(), 'day'))
                              }else if(FilterBy==='checkout'){
                                accomodation = hotelList.filter(item => moment(item.acc_check_out).isSame(moment(), 'day'))
                              }else{
                                accomodation = hotelList
                              }
                            }else if(FilterOption==='tomorrow'){
                              if(FilterBy==='checkin'){
                              accomodation = hotelList.filter(item => moment(item.acc_check_in).isSame(moment().add(1, 'day'), 'day'))
                              }else if(FilterBy==='checkout'){
                                accomodation = hotelList.filter(item => moment(item.acc_check_out).isSame(moment().add(1, 'day'), 'day'))
                              }else{
                                accomodation = hotelList
                              }
                            }else if(FilterOption==='week'){
                              if(FilterBy==='checkin'){
                              accomodation = hotelList.filter(item => moment(item.acc_check_in).isSame(moment(), 'week'))
                              }else if(FilterBy==='checkout'){
                                accomodation = hotelList.filter(item => moment(item.acc_check_out).isSame(moment(), 'week'))
                              }else{
                                accomodation = hotelList
                              }
                            }else if(FilterOption==='month'){
                              if(FilterBy==='checkin'){
                              accomodation = hotelList.filter(item => moment(item.acc_check_in).isSame(moment(), 'month'))
                              }else if(FilterBy==='checkout'){
                                accomodation = hotelList.filter(item => moment(item.acc_check_out).isSame(moment(), 'month'))
                              }else{
                                accomodation = hotelList
                              }
                            }else{
                              accomodation = hotelList
                            }
                          }else{
                            accomodation = hotelList
                          }
                        }
                        if (
                          item?.accomodation_details_more &&
                          item?.accomodation_details_more !== null &&
                          item?.accomodation_details_more !== '' &&
                          item?.accomodation_details_more !== 'null'
                        ) {
                          const hotelListmore = JSON.parse(item?.accomodation_details_more)
                          if(isSubmit && FilterOption !== '' &&FilterOption !== 'all' && FilterBy !== ''){
                            var todate=moment(customDateTo);
                            var fromdate=moment(customDateFrom);
                            if(FilterBy==='checkin'  && FilterOption==='custom' && customDateFrom !== ''){
                              accomodationmore = hotelListmore.filter(item => moment(item.more_acc_check_in).isBetween(fromdate, todate, 'day', '[]'))
                            }else if(FilterBy==='checkout'  && FilterOption==='custom' && customDateTo !== ''){
                              accomodationmore = hotelListmore.filter(item => moment(item.more_acc_check_out).isBetween(fromdate, todate, 'day', '[]'))
                            }else{
                              accomodationmore = hotelListmore
                            }
                          }else{
                            accomodationmore = hotelListmore
                          }
                        }
                        if (
                          item?.flights_details &&
                          item?.flights_details !== null
                        ) {
                          DepartureFlight = JSON.parse(item?.flights_details)
                        }
                        if (
                          item?.transportation_details &&
                          item?.transportation_details !== null
                        ) {
                          const transferlist = JSON.parse(
                            item?.transportation_details
                          )
                          var newtransferlist = transferlist.filter(
                            item => item.transportation_pick_up_location !== ''
                          )
                          transferDetail = newtransferlist
                        }
                      };
                      return (
                        <tr key={`${item.id}-${index}`}>
                          <td>
                            <h6 class='mb-0'>{index + 1}</h6>
                          </td>
                          <td>
                            Invoice No :{' '}
                            {item?.domain_name === 'Inovice Booking'
                              ? item?.generate_id
                              : item.invoice_no}
                            {item.domain_name === 'Hotel Booking' ? (
                              <div>
                                HCN No : {item.hcn_Number}
                                <br />
                                Guest Name : {item.lead_passenger}
                              </div>
                            ) : (
                              <div>
                                {item?.domain_name === 'Inovice Booking'
                                  ? 'Guest Name:' +
                                    item?.f_name +
                                    ' ' +
                                    item?.middle_name
                                  : 'Traveller Name :' + item?.lead_passenger}
                              </div>
                            )}
                          </td>
                          {item.domain_name === 'Hotel Booking' && (
                            <td>
                              <h6 class='mb-0'>
                                {reservation.hotel_details.hotel_name}{' '}
                              </h6>
                              {reservation?.hotel_details?.rooms.map(
                                (item, index) => (
                                  <p className='mt-0 pt-0' key={index}>
                                    {index + 1} {item.room_name}-
                                    {item.room_rates.map((rate, index) => (
                                      <span key={index}>{rate.room_board}</span>
                                    ))}
                                  </p>
                                )
                              )}
                              <p>
                                {moment(
                                  reservation.hotel_details.checkIn
                                ).format('ll')}{' '}
                                <FontAwesomeIcon icon={faArrowRight} />{' '}
                                {moment(
                                  reservation.hotel_details.checkOut
                                ).format('ll')}
                              </p>
                            </td>
                          )}
                          {item.domain_name === 'Transfer Booking' && (
                            <td>
                              Departure Date :{' '}
                              {moment(item?.departure_date).format(
                                'DD-MM-YYYY'
                              )}
                            </td>
                          )}
                          {item.domain_name === 'Inovice Booking' && (
                            <td>
                                <div>
                                  {accomodation.map((acc, index) => (
                                    <div key={index} className='small '>
                                      <p className='fw-bold'>
                                        {acc?.acc_hotel_name}(
                                        {acc?.hotel_city_name})
                                      </p>
                                      {acc?.acc_qty} {acc?.hotel_type_cat}:
                                      {acc?.hotel_meal_type}
                                      <br />
                                      <span
                                        className={`${
                                          moment(acc?.acc_check_in) < moment()
                                            ? 'text-danger fw-bold'
                                            : ''
                                        }`}
                                      >
                                        {moment(acc?.acc_check_in).format(
                                          'DD/MM/YYYY'
                                        )}
                                      </span>
                                      <i className='fa-solid fa-arrow-right'></i>{' '}
                                      {moment(acc?.acc_check_out).format(
                                        'DD/MM/YYYY'
                                      )}
                                    </div>
                                  ))}
                                  {accomodationmore.map((acc, index) => (
                                    <div key={index} className='small  mt-2'>
                                      <p className='fw-bold'>
                                        {acc?.more_acc_hotel_name} (
                                        {acc?.more_hotel_city})
                                      </p>
                                      {acc?.more_acc_qty} X{' '}
                                      {acc?.more_hotel_type_cat}:
                                      {acc?.more_hotel_meal_type}
                                      <br />
                                      {moment(acc?.more_acc_check_in).format(
                                        'DD/MM/YYYY'
                                      )}{' '}
                                      <i className='fa-solid fa-arrow-right'></i>{' '}
                                      {moment(acc?.more_acc_check_out).format(
                                        'DD/MM/YYYY'
                                      )}
                                    </div>
                                  ))}
                                </div>
                              {DepartureFlight.length > 0 && (
                                <div>
                                  <p className='fw-bold text-center mt-2'>
                                    Flight Departure Details
                                  </p>
                                  {DepartureFlight.map((flight, index) => (
                                    <div
                                      key={index}
                                      className='small border-bottom'
                                    >
                                      <i className='fa-solid fa-plane'></i>{' '}
                                      {flight?.other_Airline_Name2} (
                                      {flight?.departure_flight_number})
                                      <br />
                                      <i className='fa-solid fa-plane-departure'>
                                        {' '}
                                      </i>{' '}
                                      {flight?.departure_airport_code}
                                      <br />
                                      <i className='fa-solid fa-plane-arrival'></i>{' '}
                                      {flight?.arrival_airport_code}
                                      <br />
                                      <i className='fa-solid fa-calendar'></i>{' '}
                                      {moment(flight?.departure_time).format(
                                        'DD/MM/YYYY'
                                      )}{' '}
                                      | <i className='fa-solid fa-calendar'></i>{' '}
                                      {moment(flight?.arrival_time).format(
                                        'DD/MM/YYYY'
                                      )}
                                    </div>
                                  ))}
                                </div>
                              )}
                              {transferDetail.length > 0 && (
                                <div>
                                  <p className='fw-bold text-center mt-2'>
                                    Transfer Details
                                  </p>
                                  {transferDetail.map((transf, index) => (
                                    <div
                                      key={index}
                                      className='small border-bottom'
                                    >
                                      <i className='fa-solid fa-car'></i>{' '}
                                      {transf?.transportation_pick_up_location}
                                      <br />
                                      <i className='fa-solid fa-building'>
                                        {' '}
                                      </i>{' '}
                                      {transf?.transportation_drop_off_location}
                                      <br />
                                      <i className='fa-solid fa-calendar'></i>{' '}
                                      {moment(
                                        transf?.transportation_pick_up_date
                                      ).format('DD/MM/YYYY')}{' '}
                                      | <i className='fa-solid fa-calendar'></i>{' '}
                                      {moment(
                                        transf?.transportation_drop_of_date
                                      ).format('DD/MM/YYYY')}
                                    </div>
                                  ))}
                                </div>
                              )}
                              {item?.visa_type &&
                                item?.visa_Pax &&
                                item?.visa_type !== null &&
                                item?.visa_Pax !== null && (
                                  <div>
                                    <div className='small mt-2'>
                                      <i class='fa-brands fa-cc-visa'></i>{' '}
                                      {item?.visa_Pax} X {item?.visa_type}
                                    </div>
                                  </div>
                                )}
                            </td>
                          )}
                          {item.domain_name === 'Hotel Booking' ? (
                            <td>
                              {meallist.map((meal, index) => (
                                <small className='mt-0 pt-0' key={index}>
                                  {index + 1} {meal.name}
                                  <br />
                                </small>
                              ))}
                            </td>
                          ) : (
                            <td></td>
                          )}
                          {item.domain_name === 'Hotel Booking' ? (
                            <td>
                              {' '}
                              <div
                                class={`badge ${
                                  item.status.toLowerCase() === 'confirmed'
                                    ? 'text-bg-success'
                                    : item.status.toLowerCase() === 'cancelled'
                                    ? 'text-bg-danger '
                                    : 'text-bg-info'
                                } `}
                              >
                                {item.status}
                              </div>
                              <br />
                              {item.payment_Reject_Status === 'Rejected' ? (
                                <div className='badge bg-danger text-danger bg-opacity-10'>
                                  Payment Rejected
                                </div>
                              ) : (
                                <div
                                  class={`mt-1 badge ${
                                    payment == 'No Payment'
                                      ? 'bg-danger text-danger'
                                      : 'bg-success text-success'
                                  } bg-opacity-10 `}
                                >
                                  {payment}
                                </div>
                              )}
                            </td>
                          ) : (
                            <td>
                              {' '}
                              {item?.domain_name === 'Inovice Booking' ? (
                                <div
                                  class={`badge ${
                                    item.confirm === null
                                      ? 'text-bg-info'
                                      : item.confirm === '1'
                                      ? 'text-bg-success'
                                      : item.confirm.toLowerCase() ===
                                        'cancelled'
                                      ? 'text-bg-danger '
                                      : 'text-bg-info'
                                  } `}
                                >
                                  {item.confirm === null
                                    ? 'Tentative'
                                    : 'Confirmed'}
                                </div>
                              ) : (
                                <div
                                  class={`badge ${
                                    item.booking_status.toLowerCase() ===
                                    'confirmed'
                                      ? 'text-bg-success'
                                      : item.booking_status.toLowerCase() ===
                                        'cancelled'
                                      ? 'text-bg-danger '
                                      : 'text-bg-info'
                                  } `}
                                >
                                  {item.booking_status}
                                </div>
                              )}
                            </td>
                          )}
                          <td>
                            {item.domain_name === 'Hotel Booking' ? (
                              <a
                                href={`/hotel_booking_invoice/${item.invoice_no}`}
                                target='_blank'
                                rel='noopener noreferrer'
                              >
                                <p class='btn btn-sm btn-primary-soft mb-0'>
                                  View Voucher
                                </p>
                              </a>
                            ) : (
                              <a
                                href={` ${
                                  item?.domain_name === 'Inovice Booking'
                                    ? '/voucher/' + item.id
                                    : ' /transfer_invoice/' + item.invoice_no
                                }`}
                                target='_blank'
                                rel='noopener noreferrer'
                              >
                                <p class='btn btn-sm btn-primary-soft mb-0'>
                                  View Voucher
                                </p>
                              </a>
                            )}
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ArrivalList
