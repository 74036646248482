import React, { useEffect, useState } from 'react'
import header from '../../Images/Pages/header.png'
import footer from '../../Images/Pages/footer.png'
import tentative from '../../Images/tentative.png'
import confirm from '../../Images/conf.png'
import axios from 'axios'
import {
  ApiEndPoint,
  Hotelapitoken
} from '../../Components/GlobalData/GlobalData'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import Loading from '../../Components/Loading/Loader'
import moment from 'moment'
import PerPerson from './PerPerson'
function BookingInvoice () {
  const baseurl = ApiEndPoint()
  const token = Hotelapitoken()
  const { id } = useParams()
  const navigation = useNavigate()
  const [invoiceDate, setInvoiceDate] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const [transportDetail, setTransportDetail] = useState([])
  const [paymentDetail, setPaymentDetail] = useState([])
  const [transportDetailMore, setTransportDetailMore] = useState([])
  const [flightDetails, setFlightDetails] = useState([])
  const [ziaratDetails, setZiaratDetails] = useState([])
  const [moreVisaDetail, setMoreVisaDetail] = useState([])
  const [returnflightDetails, setReturnFlightDetails] = useState([])
  const [flightCostingDetails, setFlightCostingDetails] = useState([])
  const [accomodationDetail, setAccomodationDetail] = useState([])
  const [accomodationDetailMore, setAccomodationDetailMore] = useState([])
  var UserDetail = JSON.parse(localStorage.getItem('agentdata'))
  useEffect(() => {
    window.scroll(0, 0)
    if (UserDetail === null) {
      navigation('/login')
      return
    }
    GetInvoiceData()
  }, [])

  const GetInvoiceData = async () => {
    var data = {
      token: token,
      b2b_Agent_Id: UserDetail.id,
      invoice_Id: id
    }
    try {
      const response = await axios.post(
        baseurl + '/api/invoice_Agent_B2B',
        data
      )
      setIsLoading(false)
      if (response.data.message === 'success') {
        // console.log(response.data)
        setInvoiceDate(response.data.invoice_Data);
        if (
          response.data?.invoice_Data?.total_paid_amount !== null &&
          response.data?.invoice_Data?.total_paid_amount !== ''
        ) {
          setPaymentDetail(response.data?.total_paid_amount)
        }
        if (
          response.data?.invoice_Data?.accomodation_details !== null &&
          response.data?.invoice_Data?.accomodation_details_more !== ''
        ) {
          setAccomodationDetail(
            JSON.parse(response.data?.invoice_Data?.accomodation_details)
          )
        }
        if (
          response.data?.invoice_Data?.accomodation_details_more !== null &&
          response.data?.invoice_Data?.accomodation_details_more !== 'null' &&
          response.data?.invoice_Data?.accomodation_details_more !== ''
        ) {
          setAccomodationDetailMore(
            JSON.parse(response.data?.invoice_Data?.accomodation_details_more)
          )
        }
        if (response.data?.invoice_Data?.flights_details !== null) {
          setFlightDetails(
            JSON.parse(response.data?.invoice_Data?.flights_details)
          )
        }
        if (response.data?.invoice_Data?.return_flights_details !== null) {
          setReturnFlightDetails(
            JSON.parse(response.data?.invoice_Data?.return_flights_details)
          )
        }
        if (response.data?.invoice_Data?.more_visa_details !== null) {
          setMoreVisaDetail(
            JSON.parse(response.data?.invoice_Data?.more_visa_details)
          )
        }
        if (response.data?.invoice_Data?.flights_Pax_details !== null) {
          setFlightCostingDetails(
            JSON.parse(response.data?.invoice_Data?.flights_Pax_details)
          )
        }
        if (response.data?.invoice_Data?.transportation_details !== null) {
          const transferlist = JSON.parse(
            response.data?.invoice_Data?.transportation_details
          )
          var newtransferlist = transferlist.filter(
            item => item.transportation_pick_up_location !== ''
          )
          setTransportDetail(newtransferlist)
        }
        if (response.data?.invoice_Data?.transportation_details_more !== null) {
          setTransportDetailMore(
            JSON.parse(response.data?.invoice_Data?.transportation_details_more)
          )
        }
        if (
          response.data?.invoice_Data?.ziyarat_details !== null &&
          response.data?.invoice_Data?.ziyarat_details !== ''
        ) {
          setZiaratDetails(
            JSON.parse(response.data?.invoice_Data?.ziyarat_details)
          )
        }
      }
    } catch (error) {
      setIsLoading(false)
      console.log(error)
    }
  }
  return (
    <div className='booking_invoice'>
      {isLoading && <Loading />}
      <div class='cs-container' style={{ background: '#ffff' }}>
        <div class='cs-invoice cs-style1'>
          <div class='cs-invoice_in' id='download_section'>
            <img src={header} alt='letterhead' />
            <div class='cs-invoice_head cs-mb10'>
              <div class='cs-invoice_left'>
                <p
                  style={{
                    color: 'rgb(227 186 34)',
                    fontSize: '30px',
                    textTransform: 'uppercase',
                    marginTop: '17px'
                  }}
                >
                  <b class='cs-invoice_number cs-mb0'>Invoice No:</b>
                  <b style={{ color: '#000' }}> {invoiceDate?.generate_id} </b>
                </p>
                <p>
                  {invoiceDate.confirm !== 1 ? (
                    <>
                      {invoiceDate.cancellation_Status !== 1 ? (
                        <b class='cs-primary_color'>
                          <img src={tentative} alt='tentative' />
                        </b>
                      ) : (
                        <h4 className='text-danger'>CANCELLED</h4>
                      )}
                    </>
                  ) : (
                    <b class='cs-primary_color'>
                      <img src={confirm} alt='tentative' />
                    </b>
                  )}
                  <br />
                  <b class='cs-primary_color d-none'>
                    {' '}
                    Duration : 1 Nights
                  </b>{' '}
                  <br />
                  <b class='cs-primary_color'>
                    {moment(invoiceDate?.created_at).format('LL')}
                  </b>
                </p>
              </div>

              <div class='cs-invoice_right cs-text_right'>
                <b style={{ marginTop: '25px', display: 'block' }}></b>
                <b class='cs-primary_color'>
                  {' '}
                  Agent Company Name: {invoiceDate?.b2b_Agent_Company_Name}
                </b>{' '}
                <br />
                <b class='cs-primary_color'>
                  {' '}
                  Lead Passenger Name : {invoiceDate?.f_name}{' '}
                  {invoiceDate?.middle_name}{' '}
                </b>
                <br />
                <b class='cs-primary_color'></b>
                <p>
                  <br />
                  <br />
                  <br />
                  <br />
                </p>
              </div>
            </div>
            <div class='cs-table cs-style1'>
              {accomodationDetail.length > 0 && (
                <div class='t-heading-top'>
                  <i class='fa-solid fa-bed'></i> Room Details (Res #{' '}
                  {invoiceDate?.hotel_reservation_number})
                </div>
              )}
              <div class='cs-round_border'>
                {invoiceDate.all_services_quotation !== '1' ? (
                  <>
                    {accomodationDetail.length !== 0 && (
                      <div class='cs-table_responsive'>
                        <table class='cs-border_less'>
                          <tbody>
                            <tr>
                              <td
                                class='cs-primary_color cs-semi_bold cs-f16'
                                colspan='3'
                              >
                                Rooms
                              </td>
                              <td class='cs-primary_color cs-semi_bold cs-f16'>
                                Hotel Name/City
                              </td>
                              <td class='cs-primary_color cs-semi_bold cs-f16'>
                                Price Per Room/Night
                              </td>
                              <td class='cs-primary_color cs-semi_bold cs-f16'>
                                Total Room Price
                              </td>
                              <td class='cs-primary_color cs-semi_bold cs-f16'>
                                Total Price
                              </td>
                            </tr>
                            {accomodationDetail.map((item, index) => (
                              <tr key={index}>
                                <td
                                  class='cs-primary_color cs-semi_bold cs-f14'
                                  colspan='3'
                                >
                                  <i class='fa-solid fa-bed'></i>
                                  {item?.hotel_type_cat} Rooms X {item?.acc_qty}
                                </td>
                                <td class='cs-f14'>
                                  {item?.acc_hotel_name} (
                                  {item?.hotel_meal_type})
                                  <br />
                                  <b id='room_view_1'>
                                    ({item?.hotel_room_view})
                                  </b>
                                  (Res # {item?.room_reservation_no})
                                  <br />
                                  <img
                                    style={{
                                      border: '0',
                                      maxWidth: '15px',
                                      height: 'auto',
                                      verticalAlign: 'middle'
                                    }}
                                    src='https://system.alhijaztours.net/public/invoice/assets/img/calendar-check-solid.svg'
                                    alt='Logo'
                                  />
                                  {moment(item?.acc_check_in).format(
                                    'DD/MM/YYYY'
                                  )}{' '}
                                  <img
                                    style={{
                                      border: '0',
                                      maxWidth: '15px',
                                      height: 'auto',
                                      verticalAlign: 'middle'
                                    }}
                                    src='https://system.alhijaztours.net/public/invoice/assets/img/calendar-check-solid.svg'
                                    alt='Logo'
                                  />
                                  {moment(item?.acc_check_out).format(
                                    'DD/MM/YYYY'
                                  )}{' '}
                                </td>

                                {Number(item?.hotel_invoice_markup) > 0 &&
                                Number(item?.acc_no_of_nightst) > 0 ? (
                                  <td class='cs-f14'>
                                    {invoiceDate?.currency_symbol}{' '}
                                    <a id='main_ACPP_1'>
                                      {Number(item?.hotel_invoice_markup) /
                                        Number(item?.acc_no_of_nightst)}{' '}
                                    </a>
                                  </td>
                                ) : (
                                  <td class='cs-f14'>
                                    {invoiceDate?.currency_symbol}{' '}
                                    <a id='main_ACPP_1'>
                                      {Number(item?.acc_price_purchase) /
                                        Number(item?.acc_no_of_nightst)}
                                    </a>
                                  </td>
                                )}

                                <td class='cs-f14'>
                                  {invoiceDate?.currency_symbol}{' '}
                                  <a id='with_night_ACPP_1'>
                                    {item?.hotel_invoice_markup}
                                  </a>
                                </td>
                                {}
                                <td class='cs-f14'>
                                  {invoiceDate?.currency_symbol}{' '}
                                  <a id='with_pax_ACPP_1'>
                                    {Number(item?.hotel_invoice_markup) > 0
                                      ? Number(item?.hotel_invoice_markup)
                                      : 0 * Number(item?.acc_qty) > 0
                                      ? Number(item?.acc_qty)
                                      : 0}{' '}
                                  </a>
                                </td>
                              </tr>
                            ))}
                            {accomodationDetailMore.map((item, index) => (
                              <tr key={index}>
                                <td
                                  class='cs-primary_color cs-semi_bold cs-f14'
                                  colspan='3'
                                >
                                  <i class='fa-solid fa-bed'></i>
                                  {item?.more_hotel_type_cat} Rooms X{' '}
                                  {item?.more_acc_qty}
                                </td>
                                <td class='cs-f14'>
                                  {item?.more_acc_hotel_name} (
                                  {item?.more_hotel_meal_type})<br />
                                  <b id='room_view_1'>
                                    ({item?.more_hotel_room_view})
                                  </b>
                                  (Res # {item?.more_room_reservation_no})
                                  <br />
                                  <img
                                    style={{
                                      border: '0',
                                      maxWidth: '15px',
                                      height: 'auto',
                                      verticalAlign: 'middle'
                                    }}
                                    src='https://system.alhijaztours.net/public/invoice/assets/img/calendar-check-solid.svg'
                                    alt='Logo'
                                  />
                                  {moment(item?.more_acc_check_in).format(
                                    'DD/MM/YYYY'
                                  )}{' '}
                                  <img
                                    style={{
                                      border: '0',
                                      maxWidth: '15px',
                                      height: 'auto',
                                      verticalAlign: 'middle'
                                    }}
                                    src='https://system.alhijaztours.net/public/invoice/assets/img/calendar-check-solid.svg'
                                    alt='Logo'
                                  />
                                  {moment(item?.more_acc_check_out).format(
                                    'DD/MM/YYYY'
                                  )}{' '}
                                </td>

                                {Number(item?.more_hotel_invoice_markup) > 0 &&
                                Number(item?.more_acc_no_of_nightst) > 0 ? (
                                  <td class='cs-f14'>
                                    {invoiceDate?.currency_symbol}{' '}
                                    <a id='main_ACPP_1'>
                                      {Number(item?.more_hotel_invoice_markup) /
                                        Number(
                                          item?.more_acc_no_of_nightst
                                        )}{' '}
                                    </a>
                                  </td>
                                ) : (
                                  <td class='cs-f14'>
                                    {invoiceDate?.currency_symbol}{' '}
                                    <a id='main_ACPP_1'>
                                      {Number(item?.more_acc_price_purchase) /
                                        Number(item?.more_acc_no_of_nightst)}
                                    </a>
                                  </td>
                                )}

                                <td class='cs-f14'>
                                  {invoiceDate?.currency_symbol}{' '}
                                  <a id='with_night_ACPP_1'>
                                    {item?.more_hotel_invoice_markup}
                                  </a>
                                </td>
                                <td class='cs-f14'>
                                  {invoiceDate?.currency_symbol}{' '}
                                  <a id='with_pax_ACPP_1'>
                                    {Number(item?.more_hotel_invoice_markup) > 0
                                      ? Number(item?.more_hotel_invoice_markup)
                                      : 0 * Number(item?.more_acc_qty) > 0
                                      ? Number(item?.more_acc_qty)
                                      : 0}
                                  </a>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    {accomodationDetail.map((item, index) => (
                      <div key={index} className='row p-3'>
                        <h6>
                          <i className='fa-solid fa-hotel'></i>Hotel Name :{' '}
                          {item.acc_hotel_name}
                        </h6>
                        <div className='col-md-4 mt-3'>
                          <i className='fa-solid fa-calendar-check'></i>Check In
                          : {moment(item?.acc_check_in).format('DD-MM-YYYY')}
                        </div>
                        <div className='col-md-4 mt-3'>
                          <i className='fa-solid fa-calendar-check'></i>Check
                          Out :
                          {moment(item?.acc_check_out).format('DD-MM-YYYY')}
                        </div>
                        <div className='col-md-4 mt-3'>
                          <i className='fa-solid fa-cutlery'></i> Meal Type:{' '}
                          {item.hotel_meal_type} (Res #{' '}
                          {item.room_reservation_no})
                        </div>
                      </div>
                    ))}
                    {/* {accomodationDetailMore.map((item, index) => (
                  <div key={index} className='row p-3'>
                    <h6><i className='fa-solid fa-hotel'></i>Hotel Name : {item.more_acc_hotel_name}</h6>
                    <div className='col-md-4 mt-3'>
                    <i className='fa-solid fa-calendar-check'></i>Check In : {moment(item?.more_acc_check_in).format(
                                    'DD-MM-YYYY'
                                  )}
                    </div>
                    <div className='col-md-4 mt-3'>
                    <i className='fa-solid fa-calendar-check'></i>Check Out :{moment(item?.more_acc_check_out).format(
                                    'DD-MM-YYYY'
                                  )}
                    </div>
                    <div className='col-md-4 mt-3'>
                    <i className='fa-solid fa-cutlery'></i> Meal Type: {item.more_hotel_meal_type} (Res # {item.more_room_reservation_no})
                    </div>
                  </div>
                  ))} */}
                  </>
                )}
              </div>
            </div>
            {flightDetails.length !== 0 && (
              <div class='cs-table cs-style1'>
                <div class='t-heading-top'>
                  <i class='fa-solid fa-plane'></i> Departure Details (
                  {flightDetails.length > 0
                    ? flightDetails[0].departure_flight_route_type
                    : ''}
                  )
                </div>
                {flightDetails.map((item, index) => (
                  <div key={index}>
                    <div className='row px-1 py-3'>
                      <div className='col-md-6'>
                        <h5>
                          {' '}
                          <i class='fa-solid fa-plane'></i>{' '}
                          {item.departure_airport_code}
                        </h5>
                      </div>
                      <div className='col-md-6 text-end'>
                        <h5>
                          {' '}
                          <i class='fa-solid fa-plane'></i>{' '}
                          {item.arrival_airport_code}
                        </h5>
                      </div>
                    </div>
                    <div class='cs-round_border'>
                      <div class='cs-table_responsive'>
                        <table class='cs-border_less'>
                          <tbody>
                            <tr className='text-center'>
                              <td class='cs-primary_color cs-semi_bold cs-f16'>
                                Departure
                              </td>
                              <td class='cs-primary_color cs-semi_bold cs-f16'>
                                Arrival
                              </td>
                              <td class='cs-primary_color cs-semi_bold cs-f16'>
                                Airline Name
                              </td>
                              {invoiceDate.all_services_quotation !== '1' && (
                                <td class='cs-primary_color cs-semi_bold cs-f16'>
                                  Duration
                                </td>
                              )}
                            </tr>
                            <tr className='text-center'>
                              <td class='cs-primary_color  cs-f14'>
                                {item.departure_airport_code}
                                <br />
                                {moment(item.departure_time).format('LLL')}
                              </td>
                              <td class='cs-f14'>
                                {item.arrival_airport_code}
                                <br />
                                {moment(item.arrival_time).format('LLL')}
                              </td>
                              <td>
                                {item.other_Airline_Name2}
                                <br />
                                {item.departure_flight_number}
                              </td>
                              {invoiceDate.all_services_quotation !== '1' && (
                                <td class='cs-f14'>{item.total_Time}</td>
                              )}
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
            {returnflightDetails.length !== 0 && (
              <div class='cs-table cs-style1'>
                <div class='t-heading-top'>
                  <i class='fa-solid fa-plane'></i> Return Details (
                  {returnflightDetails.length > 0
                    ? returnflightDetails[0].return_flight_route_type
                    : ''}
                  )
                </div>
                {returnflightDetails.map((item, index) => (
                  <div key={index}>
                    <div className='row px-1 py-3'>
                      <div className='col-md-6'>
                        <h5>
                          {' '}
                          <i class='fa-solid fa-plane'></i>{' '}
                          {item.return_departure_airport_code}
                        </h5>
                      </div>
                      <div className='col-md-6 text-end'>
                        <h5>
                          {' '}
                          <i class='fa-solid fa-plane'></i>{' '}
                          {item.return_arrival_airport_code}
                        </h5>
                      </div>
                    </div>
                    <div class='cs-round_border'>
                      <div class='cs-table_responsive'>
                        <table class='cs-border_less'>
                          <tbody>
                            <tr className='text-center'>
                              <td class='cs-primary_color cs-semi_bold cs-f16'>
                                Departure
                              </td>
                              <td class='cs-primary_color cs-semi_bold cs-f16'>
                                Arrival
                              </td>
                              <td class='cs-primary_color cs-semi_bold cs-f16'>
                                Airline Name
                              </td>
                              {invoiceDate.all_services_quotation !== '1' && (
                                <td class='cs-primary_color cs-semi_bold cs-f16'>
                                  Duration
                                </td>
                              )}
                            </tr>
                            <tr className='text-center'>
                              <td class='cs-primary_color  cs-f14'>
                                {item.return_departure_airport_code}
                                <br />
                                {moment(item.return_departure_time).format(
                                  'LLL'
                                )}
                              </td>
                              <td class='cs-f14'>
                                {item.return_arrival_airport_code}
                                <br />
                                {moment(item.return_arrival_time).format('LLL')}
                              </td>
                              <td>
                                {item.return_other_Airline_Name2}
                                <br />
                                {item.return_departure_flight_number}
                              </td>
                              {invoiceDate.all_services_quotation !== '1' && (
                                <td class='cs-f14'>{item.return_total_Time}</td>
                              )}
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
            {invoiceDate.all_services_quotation !== '1' && (
              <>
                {flightCostingDetails.length !== 0 && (
                  <div class='cs-table cs-style1'>
                    <div class='t-heading-top'>
                      <i class='fa-solid fa-plane'></i> Flight Costing Details
                    </div>
                    <div class='cs-round_border'>
                      <div class='cs-table_responsive'>
                        <table class='cs-border_less'>
                          <tbody>
                            <tr className='text-center'>
                              <td class='cs-primary_color cs-semi_bold cs-f16'>
                                Type
                              </td>
                              <td class='cs-primary_color cs-semi_bold cs-f16'>
                                Price/Seat
                              </td>
                              <td class='cs-primary_color cs-semi_bold cs-f16'>
                                Seats
                              </td>
                              <td class='cs-primary_color cs-semi_bold cs-f16'>
                                Total Price
                              </td>
                            </tr>
                            {flightCostingDetails.map((item, index) => (
                              <tr key={index} className='text-center'>
                                <td class='cs-primary_color  cs-f14'>
                                  {Number(item.flights_adult_seats) > 0
                                    ? item.flights_adult_type
                                    : Number(item.flights_child_seats) > 0
                                    ? item.flights_child_type
                                    : Number(item.flights_infant_seats) > 0
                                    ? item.flights_infant_type
                                    : ''}
                                </td>
                                <td class='cs-f14'>
                                  {invoiceDate.currency_symbol}{' '}
                                  {Number(item.flights_adult_seats) > 0
                                    ? item.flights_sale_price_per_seat_adult !==
                                      null
                                      ? item.flights_sale_price_per_seat_adult
                                      : item.flights_cost_per_seats_adult
                                    : Number(item.flights_child_seats) > 0
                                    ? item.flights_sale_price_per_seat_child !==
                                      null
                                      ? item.flights_sale_price_per_seat_child
                                      : item.flights_cost_per_seats_child
                                    : Number(item.flights_infant_seats) > 0
                                    ? item.flights_sale_price_per_seat_infant !==
                                      null
                                      ? item.flights_sale_price_per_seat_infant
                                      : item.flights_cost_per_seats_infant
                                    : ''}
                                </td>
                                <td>
                                  {Number(item.flights_adult_seats) > 0
                                    ? item.flights_adult_seats
                                    : Number(item.flights_child_seats) > 0
                                    ? item.flights_child_seats
                                    : Number(item.flights_infant_seats) > 0
                                    ? item.flights_infant_seats
                                    : ''}
                                </td>
                                <td class='cs-f14'>
                                  {invoiceDate.currency_symbol}{' '}
                                  {Number(item.flights_adult_seats) > 0
                                    ? item.flights_sale_price_adult !== null
                                      ? item.flights_sale_price_adult
                                      : item.flights_cost_per_seats_adult
                                    : Number(item.flights_child_seats) > 0
                                    ? item.flights_sale_price_child !== null
                                      ? item.flights_sale_price_child
                                      : item.flights_cost_per_seats_child
                                    : Number(item.flights_infant_seats) > 0
                                    ? item.flights_sale_price_infant !== null
                                      ? item.flights_sale_price_infant
                                      : item.flights_cost_per_seats_infant
                                    : ''}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
            {moreVisaDetail.length !== 0 ||
              (invoiceDate.visa_type !== null && (
                <div class='cs-table cs-style1'>
                  <div class='t-heading-top'>
                    <i class='fa-solid fa-passport'></i> Visa Invoice Details
                  </div>
                  <div class='cs-round_border'>
                    <div class='cs-table_responsive'>
                      <table class='cs-border_less'>
                        <tbody>
                          <tr className='text-center'>
                            <td class='cs-primary_color cs-semi_bold cs-f16'>
                              Visa Type
                            </td>
                            {invoiceDate.all_services_quotation !== '1' && (
                              <>
                                <td class='cs-primary_color cs-semi_bold cs-f16'>
                                  Visa Pax
                                </td>
                                <td class='cs-primary_color cs-semi_bold cs-f16'>
                                  Visa Price/Person
                                </td>
                                <td class='cs-primary_color cs-semi_bold cs-f16'>
                                  Visa Price
                                </td>
                              </>
                            )}
                          </tr>
                          <tr className='text-center'>
                            <td class='cs-primary_color  cs-f14'>
                              {invoiceDate.visa_type}
                            </td>
                            {invoiceDate.all_services_quotation !== '1' && (
                              <>
                                <td class='cs-f14'>{invoiceDate.visa_Pax}</td>
                                <td>
                                  {invoiceDate.currency_symbol}{' '}
                                  {invoiceDate.total_visa_markup_value}
                                </td>
                                <td class='cs-f14'>
                                  {invoiceDate.currency_symbol}{' '}
                                  {invoiceDate?.total_visa_markup_value &&
                                  Number(invoiceDate?.total_visa_markup_value) >
                                    0
                                    ? Number(
                                        invoiceDate?.total_visa_markup_value
                                      ) * Number(invoiceDate.visa_Pax)
                                    : Number(invoiceDate.visa_fee) *
                                      Number(invoiceDate.no_of_pax_days)}
                                </td>
                              </>
                            )}
                          </tr>
                          {invoiceDate.all_services_quotation !== '1' && (
                            <>
                              {moreVisaDetail.map((item, index) => (
                                <tr key={index} className='text-center'>
                                  <td class='cs-primary_color cs-semi_bold cs-f14'>
                                    {item.more_visa_type}
                                  </td>
                                  <td class='cs-f14'>{item.more_visa_Pax}</td>
                                  <td>
                                    {invoiceDate.currency_symbol}{' '}
                                    {Number(item.more_total_visa_markup_value) +
                                      Number(item.more_exchange_rate_visa_fee)}
                                  </td>
                                  <td class='cs-f14'>
                                    {invoiceDate.currency_symbol}{' '}
                                    {(Number(
                                      item.more_total_visa_markup_value
                                    ) +
                                      Number(
                                        item.more_exchange_rate_visa_fee
                                      )) *
                                      Number(item.more_visa_Pax)}
                                  </td>
                                </tr>
                              ))}
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              ))}
            {transportDetail.length > 0 && (
              <div class='cs-table cs-style1'>
                <div class='t-heading-top'>
                  <i class='fa-solid fa-bus'></i> Transportation Details (
                  {transportDetail[0].transportation_vehicle_type}) (
                  {transportDetail[0].transportation_no_of_vehicle})
                </div>
                <div class='cs-round_border'>
                  <div class='cs-table_responsive'>
                    <table class='cs-border_less'>
                      <tbody>
                        <tr className='text-center'>
                          <th class='cs-primary_color '>Type</th>
                          <th class='cs-primary_color '>Pick Up</th>
                          <th class='cs-primary_color '>Drop Off</th>
                          <th class='cs-primary_color '>Pick up Date</th>
                          <th>Drop Off Date</th>
                        </tr>
                        {transportDetail.map((item, index) => (
                          <tr key={index} className='text-center'>
                            <td class='cs-primary_color cs-semi_bold cs-f14'>
                              {item.transportation_trip_type}
                            </td>
                            <td class='cs-f14'>
                              {item.transportation_pick_up_location}
                            </td>
                            <td>{item.transportation_drop_off_location}</td>
                            <td class='cs-f14'>
                              {moment(item.transportation_pick_up_date).format(
                                'LLL'
                              )}
                            </td>
                            <td class='cs-f14'>
                              {moment(item.transportation_drop_of_date).format(
                                'LLL'
                              )}
                            </td>
                          </tr>
                        ))}
                        {transportDetailMore.map((item, index) => (
                          <tr key={index} className='text-center'>
                            <td class='cs-primary_color  cs-f14'>All Round</td>
                            <td class='cs-f14'>
                              {item.more_transportation_pick_up_location}
                            </td>
                            <td>
                              {item.more_transportation_drop_off_location}
                            </td>
                            <td class='cs-f14'>
                              {moment(
                                item.more_transportation_pick_up_date
                              ).format('LLL')}
                            </td>
                            <td class='cs-f14'>
                              {moment(
                                item.more_transportation_drop_of_date
                              ).format('LLL')}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
            {ziaratDetails.length > 0 && (
              <div class='cs-table cs-style1'>
                <div class='t-heading-top'>
                  <i class='fa-solid fa-bus'></i> Ziyarat Details
                </div>
                <div class='cs-round_border'>
                  <div class='cs-table_responsive'>
                    <table class='cs-border_less'>
                      <tbody>
                        <tr className='text-center'>
                          <th class='cs-primary_color '>City Name</th>
                          <th class='cs-primary_color '>Pickup Location</th>
                          <th class='cs-primary_color '>Dropoff Location</th>
                          <th class='cs-primary_color '>Pickup Time</th>
                        </tr>
                        {ziaratDetails.map((item, index) => (
                          <tr key={index} className='text-center'>
                            <td class='cs-primary_color cs-semi_bold cs-f14'>
                              {item.ziyarat_city_name}
                            </td>
                            <td class='cs-f14'>
                              {item.ziyarat_pickup_location}
                            </td>
                            <td>{item.ziyarat_dropoff_location}</td>
                            <td class='cs-f14'>
                              {moment(item.ziyarat_pickup_date).format('LLL')}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
          </div>
          {invoiceDate.all_services_quotation === '1' && (
            <PerPerson invoiceDetail={invoiceDate} />
          )}
          {paymentDetail.length > 0 && (
              <div class='cs-table cs-style1'>
                <div class='t-heading-top'>
                <i class="fa-solid fa-money-bill"></i> Payment Details
                </div>
                <div class='cs-round_border'>
                  <div class='cs-table_responsive'>
                    <table class='cs-border_less'>
                      <tbody>
                        <tr className='text-center'>
                          <th class='cs-primary_color '>Invoice No</th>
                          <th class='cs-primary_color '>Paid On</th>
                          <th class='cs-primary_color '>Total Amount</th>
                          <th class='cs-primary_color '>Total Received</th>
                          <th>Remaning</th>
                        </tr>
                        {paymentDetail.map((item, index) => (
                          <tr key={index} className='text-center'>
                            <td class=' cs-f14'>
                              {item.invoice_no}
                            </td>
                            <td class='cs-f14'>
                              {moment(item.date).format('LL')}
                              <br/>
                              {item?.remarks}
                            </td>
                            <td>{invoiceDate?.currency_symbol} {invoiceDate?.total_sale_price_all_Services}</td>
                            <td class='cs-f14'>{invoiceDate?.currency_symbol} {item?.payment_amount}</td>
                            <td class='cs-f14'>{invoiceDate?.currency_symbol} {Number(invoiceDate?.total_sale_price_all_Services)-Number(item?.payment_amount)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
           {invoiceDate.all_services_quotation !== '1' && transportDetail.length > 0 && (
          <div class='row mt-5'>
            <div class='col-lg-6'></div>
            <div class='col-lg-6'>
              <div class='cs-table cs-style1 cs-mb20'>
                <div class='cs-table_responsive'>
                  <table class='cs-border_less' style={{minWidth: '0%'}}>
                    <tbody>
                      <tr>
                        <td class='cs-bold cs-primary_color cs-f14 cs-text_center'>
                          No of Vehicle{' '}
                        </td>
                        <td class='cs-bold cs-primary_color cs-f14 cs-text_center'>
                          Price Per Vehicle{' '}
                        </td>
                        <td class='cs-bold cs-primary_color cs-f14 cs-text_center'>
                          Total Vehicle Price{' '}
                        </td>
                      </tr>
                    {transportDetail.map((item,index)=>(
                      <tr key={index}>
                        <td class='cs-f14 text-center'>
                          {item?.transportation_no_of_vehicle}
                        </td>
                        <td class='cs-f14 text-center'>
                          {invoiceDate?.currency_symbol} {item?.without_markup_price_converted_destination}
                        </td>
                        <td class='cs-f14 text-center'>
                        {invoiceDate?.currency_symbol} {item?.vehicle_total_price_with_markup_converted}
                        </td>
                      </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
           )}
          <div class='cs-table cs-style1 mt-3'>
            <div class='cs-table_responsive'>
              <table class='cs-border_less'>
                <tbody>
                  <tr>
                    <td class='cs-bold h5 cs-primary_color cs-f18'>
                      Total Amount:
                    </td>
                    <td class='cs-bold text-end h5 cs-primary_color cs-f18 cs-text_right'>
                      {invoiceDate?.currency_symbol}{' '}
                      {invoiceDate?.total_sale_price_all_Services}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class='cs-table cs-style1 mt-3'>
            <div class='cs-table_responsive'>
              <h4>Account Info</h4>
              {/* <ul class='d-none'>
                <li>PKMEZN</li>
              </ul>
              <textarea class='form-control' readonly style={{height: '130px'}} >
                PKMEZN 
              </textarea> */}

              <ul>
                <li>Account Title: UMRAH SHOP</li>
                <li>Branch Name: UBL GRAIN MARKET BWN BRANCH CODE 0603</li>
                <li>Account Number: 319699142</li>
                <li>IBAN: PK63UNIL0109000319699142</li>
              </ul>
              <div></div>
              <img style={{ width: '100%' }} src={footer} alt='letterhead' />
            </div>
          </div>
        </div>

        <div class='cs-invoice_btns cs-hide_print'>
          <a class='cs-invoice_btn cs-color1' onClick={() => window.print()}>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              class='ionicon'
              viewBox='0 0 512 512'
            >
              <path
                d='M384 368h24a40.12 40.12 0 0040-40V168a40.12 40.12 0 00-40-40H104a40.12 40.12 0 00-40 40v160a40.12 40.12 0 0040 40h24'
                fill='none'
                stroke='currentColor'
                stroke-linejoin='round'
                stroke-width='32'
              ></path>
              <rect
                x='128'
                y='240'
                width='256'
                height='208'
                rx='24.32'
                ry='24.32'
                fill='none'
                stroke='currentColor'
                stroke-linejoin='round'
                stroke-width='32'
              ></rect>
              <path
                d='M384 128v-24a40.12 40.12 0 00-40-40H168a40.12 40.12 0 00-40 40v24'
                fill='none'
                stroke='currentColor'
                stroke-linejoin='round'
                stroke-width='32'
              ></path>
              <circle cx='392' cy='184' r='24'></circle>
            </svg>
            <span>Print Or Download</span>
          </a>
          <NavLink to={`/voucher/${invoiceDate?.id}`}>
            <a class='cs-invoice_btn cs-color1'>
              <span>View Voucher</span>
            </a>
          </NavLink>
        </div>
      </div>
    </div>
  )
}
export default BookingInvoice
