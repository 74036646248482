import React, { useEffect, useState } from 'react'
var doubleRoomQty = 0
var tripleRoomQty = 0
var quadRoomQty = 0
var withoutBedQty = 0

var doubleTotalWithPax = 0
var doubleTotalWithPaxChild = 0
var doubleTotalWithPaxInfant = 0

var tripleTotalWithPax = 0
var tripleTotalWithPaxChild = 0
var tripleTotalWithPaxInfant = 0

var quadTotalWithPax = 0
var quadTotalWithPaxChild = 0
var quadTotalWithPaxInfant = 0

var withoutAccTotalWithPax = 0
var childTotalWithPax = 0
var infantTotalWithPax = 0
function PerPerson ({ invoiceDetail }) {
  const [costingDetails, setCostingDetails] = useState({})
  const [costingDetailsChild, setCostingDetailsChild] = useState({})
  const [costingDetailsInfant, setCostingDetailsInfant] = useState({})

  useEffect(() => {
    let tempDoubleRoomQty = 0
    let tempTripleRoomQty = 0
    let tempQuadRoomQty = 0
    let tempWithoutRoomQty = 0

    if (
      invoiceDetail?.all_costing_details !== null &&
      invoiceDetail?.all_costing_details !== ''
    ) {
      setCostingDetails(JSON.parse(invoiceDetail?.all_costing_details))
      const allCosting = JSON.parse(invoiceDetail?.all_costing_details)
      tempDoubleRoomQty += Number(allCosting?.double_total_pax) // Increment locally
      tempTripleRoomQty += Number(allCosting?.triple_total_pax) // Increment locally
      tempQuadRoomQty += Number(allCosting?.quad_total_pax)
      tempWithoutRoomQty += Number(allCosting?.without_acc_total_pax)
      doubleTotalWithPax = allCosting?.double_total_with_Pax
      tripleTotalWithPax = allCosting?.triple_total_with_Pax
      quadTotalWithPax = allCosting?.quad_total_with_Pax
      withoutAccTotalWithPax=allCosting?.without_acc_total_with_Pax
    }

    if (
      invoiceDetail?.all_costing_details_child !== null &&
      invoiceDetail?.all_costing_details_child !== ''
    ) {
      setCostingDetailsChild(
        JSON.parse(invoiceDetail?.all_costing_details_child)
      )
      const allCostingChild = JSON.parse(
        invoiceDetail?.all_costing_details_child
      )
      tempDoubleRoomQty += Number(allCostingChild?.double_total_pax_child) // Increment locally
      tempTripleRoomQty += Number(allCostingChild?.triple_total_pax_child)
      tempQuadRoomQty += Number(allCostingChild?.quad_total_pax_child)
      tempWithoutRoomQty += Number(allCostingChild?.child_total_pax)
      doubleTotalWithPaxChild = allCostingChild?.double_total_with_Pax_child
      tripleTotalWithPaxChild = allCostingChild?.triple_total_with_Pax_child
      quadTotalWithPaxChild = allCostingChild?.quad_total_with_Pax_child
      childTotalWithPax=allCostingChild?.child_total_with_Pax
    }

    if (
      invoiceDetail?.all_costing_details_infant !== null &&
      invoiceDetail?.all_costing_details_infant !== ''
    ) {
      setCostingDetailsInfant(
        JSON.parse(invoiceDetail?.all_costing_details_infant)
      )
      const allCostingInfant = JSON.parse(
        invoiceDetail?.all_costing_details_infant
      )
      tempDoubleRoomQty += Number(allCostingInfant?.double_total_pax_infant) // Increment locally
      tempTripleRoomQty += Number(allCostingInfant?.triple_total_pax_infant)
      tempQuadRoomQty += Number(allCostingInfant?.quad_total_pax_infant)
      tempWithoutRoomQty += Number(allCostingInfant?.infant_total_pax)
      doubleTotalWithPaxInfant = allCostingInfant?.double_total_with_Pax_infant
      tripleTotalWithPaxInfant = allCostingInfant?.triple_total_with_Pax_infant
      quadTotalWithPaxInfant = allCostingInfant?.quad_total_with_Pax_infant
      infantTotalWithPax=allCostingInfant?.infant_total_with_Pax
    }

    doubleRoomQty = tempDoubleRoomQty // Update the global variable after all calculations
    tripleRoomQty = tempTripleRoomQty
    quadRoomQty = tempQuadRoomQty
    withoutBedQty = tempWithoutRoomQty
  }, [invoiceDetail])

  return (
    <>
      <div class='cs-round_border mt-4'>
        <div class='cs-table_responsive'>
          <table class='cs-border_less'>
            <tbody>
              <tr className=''>
                <th colspan='2' class='cs-primary_color'>Rooms</th>
                <th colspan='2'>No of Pax</th>
                <th>Price</th>
              </tr>
              {doubleRoomQty > 0 && (
                <tr className=''>
                  <td colspan='2' class='cs-primary_color  cs-f14'>
                    <i className='fa-solid fa-bed'></i> Double Rooms X{' '}
                    {doubleRoomQty / 2}
                  </td>
                  <td colspan='2' class='cs-f14'>
                    {invoiceDetail?.all_costing_details &&
                      invoiceDetail?.all_costing_details !== null &&
                      Number(costingDetails?.double_total_pax) > 0 && (
                        <span> Adults {costingDetails?.double_total_pax},</span>
                      )}
                    {invoiceDetail?.all_costing_details_child &&
                      invoiceDetail?.all_costing_details_child !== null &&
                      Number(costingDetailsChild?.double_total_pax_child) >
                        0 && (
                        <span>
                          {' '}
                          Childs {costingDetailsChild?.double_total_pax_child},
                        </span>
                      )}
                    {invoiceDetail?.all_costing_details_infant &&
                      invoiceDetail?.all_costing_details_infant !== null &&
                      Number(costingDetailsInfant?.double_total_pax_infant) >
                        0 && (
                        <span>
                          {' '}
                          Infants{' '}
                          {costingDetailsInfant?.double_total_pax_infant}
                        </span>
                      )}
                  </td>
                  <td>
                    {invoiceDetail.currency_symbol}{' '}
                    {Number(doubleTotalWithPax) +
                      Number(doubleTotalWithPaxChild) +
                      Number(doubleTotalWithPaxInfant)}
                  </td>
                </tr>
              )}
              {tripleRoomQty > 0 && (
                <tr className=''>
                  <td colspan='2' class='cs-primary_color  cs-f14'>
                    <i className='fa-solid fa-bed'></i> Triple Rooms X{' '}
                    {tripleRoomQty / 3}
                  </td>
                  <td colspan='2' class='cs-f14'>
                    {invoiceDetail?.all_costing_details &&
                      invoiceDetail?.all_costing_details !== null &&
                      Number(costingDetails?.triple_total_pax) > 0 && (
                        <span> Adults {costingDetails?.triple_total_pax},</span>
                      )}
                    {invoiceDetail?.all_costing_details_child &&
                      invoiceDetail?.all_costing_details_child !== null &&
                      Number(costingDetailsChild?.triple_total_pax_child) >
                        0 && (
                        <span>
                          {' '}
                          Childs {costingDetailsChild?.triple_total_pax_child},
                        </span>
                      )}
                    {invoiceDetail?.all_costing_details_infant &&
                      invoiceDetail?.all_costing_details_infant !== null &&
                      Number(costingDetailsInfant?.triple_total_pax_infant) >
                        0 && (
                        <span>
                          {' '}
                          Infants{' '}
                          {costingDetailsInfant?.triple_total_pax_infant}
                        </span>
                      )}
                  </td>
                  <td>
                    {invoiceDetail.currency_symbol}{' '}
                    {Number(tripleTotalWithPax) +
                      Number(tripleTotalWithPaxChild) +
                      Number(tripleTotalWithPaxInfant)}
                  </td>
                </tr>
              )}
              {quadRoomQty > 0 && (
                <tr className=''>
                  <td colspan='2' class='cs-primary_color  cs-f14'>
                    <i className='fa-solid fa-bed'></i> Quad Rooms X{' '}
                    {quadRoomQty / 4}
                  </td>
                  <td colspan='2' class='cs-f14'>
                    {invoiceDetail?.all_costing_details &&
                      invoiceDetail?.all_costing_details !== null &&
                      Number(costingDetails?.quad_total_pax) > 0 && (
                        <span> Adults {costingDetails?.quad_total_pax},</span>
                      )}
                    {invoiceDetail?.all_costing_details_child &&
                      invoiceDetail?.all_costing_details_child !== null &&
                      Number(costingDetailsChild?.quad_total_pax_child) > 0 && (
                        <span>
                          {' '}
                          Childs {costingDetailsChild?.quad_total_pax_child},
                        </span>
                      )}
                    {invoiceDetail?.all_costing_details_infant &&
                      invoiceDetail?.all_costing_details_infant !== null &&
                      Number(costingDetailsInfant?.quad_total_pax_infant) >
                        0 && (
                        <span>
                          {' '}
                          Infants {costingDetailsInfant?.quad_total_pax_infant}
                        </span>
                      )}
                  </td>
                  <td>
                    {invoiceDetail.currency_symbol}{' '}
                    {Number(quadTotalWithPax) +
                      Number(quadTotalWithPaxChild) +
                      Number(quadTotalWithPaxInfant)}
                  </td>
                </tr>
              )}
              {/* {withoutBedQty > 0 && (
                <tr className=''>
                  <td colspan='2' class='cs-primary_color  cs-f14'>
                    <i className='fa-solid fa-male'></i> Without Bed 
                  </td>
                  <td colspan='2' class='cs-f14'>
                    {invoiceDetail?.all_costing_details &&
                      invoiceDetail?.all_costing_details !== null &&
                      Number(costingDetails?.without_acc_total_pax) > 0 && (
                        <span> Adults {costingDetails?.without_acc_total_pax},</span>
                      )}
                    {invoiceDetail?.all_costing_details_child &&
                      invoiceDetail?.all_costing_details_child !== null &&
                      Number(costingDetailsChild?.child_total_pax) > 0 && (
                        <span>
                          {' '}
                          Childs {costingDetailsChild?.child_total_pax},
                        </span>
                      )}
                    {invoiceDetail?.all_costing_details_infant &&
                      invoiceDetail?.all_costing_details_infant !== null &&
                      Number(costingDetailsInfant?.infant_total_pax) >
                        0 && (
                        <span>
                          {' '}
                          Infants {costingDetailsInfant?.infant_total_pax}
                        </span>
                      )}
                  </td>
                  <td>
                    {invoiceDetail.currency_symbol}{' '}
                    {Number(withoutAccTotalWithPax) +
                      Number(childTotalWithPax) +
                      Number(infantTotalWithPax)}
                  </td>
                </tr>
              )} */}
              <tr className='invoice-hr'><td colspan="12"><hr/></td></tr>
              <tr className=''>
                <th colspan='2' class='cs-primary_color '>Rooms</th>
                <th>No of Pax</th>
                <th>Price Per Person</th>
                <th>Total</th>
                </tr>
                {doubleRoomQty > 0 && (
                <tr className=''>
                  <td colspan='2' class='cs-primary_color  cs-f14'>
                    <i className='fa-solid fa-bed'></i> Adults in Double Room{' '}
                   
                  </td>
                  <td class='cs-f14 fw-bold'>
                    {costingDetails?.double_total_pax} Adults
                  </td>
                  <td>
                   {invoiceDetail?.currency_symbol} {costingDetails?.double_grand_total_amount}
                  </td>
                  <td>
                    {invoiceDetail.currency_symbol}{' '}
                    {costingDetails?.double_total_with_Pax}
                  </td>
                </tr>
              )}
               {costingDetailsChild?.double_total_pax_child && Number(costingDetailsChild?.double_total_pax_child) > 0 && (
                <tr className=''>
                  <td colspan='2' class='cs-primary_color  cs-f14'>
                    Childs in Double Room{' '}
                   
                  </td>
                  <td class='cs-f14 fw-bold'>
                    {costingDetailsChild?.double_total_pax_child} Childs
                  </td>
                  <td>
                   {invoiceDetail?.currency_symbol} {costingDetailsChild?.double_grand_total_amount_child}
                  </td>
                  <td>
                    {invoiceDetail.currency_symbol}{' '}
                    {costingDetailsChild?.double_total_with_Pax_child}
                  </td>
                </tr>
              )}
              {costingDetailsInfant?.double_total_pax_infant   && Number(costingDetailsInfant?.double_total_pax_infant  ) > 0 && (
                <tr className=''>
                  <td colspan='2' class='cs-primary_color  cs-f14'>
                    Infants in Double Room{' '}
                   
                  </td>
                  <td class='cs-f14 fw-bold'>
                    {costingDetailsInfant?.double_total_pax_infant } Infants
                  </td>
                  <td>
                   {invoiceDetail?.currency_symbol} {costingDetailsInfant?.double_grand_total_amount_infant}
                  </td>
                  <td>
                    {invoiceDetail.currency_symbol}{' '}
                    {costingDetailsInfant?.double_total_with_Pax_infant}
                  </td>
                </tr>
              )}
                {tripleRoomQty > 0 && (
                <tr className=''>
                  <td colspan='2' class='cs-primary_color  cs-f14'>
                    <i className='fa-solid fa-bed'></i> Adults in Triple Room{' '}
                   
                  </td>
                  <td class='cs-f14 fw-bold'>
                    {costingDetails?.triple_total_pax} Adults
                  </td>
                  <td>
                   {invoiceDetail?.currency_symbol} {costingDetails?.triple_grand_total_amount}
                  </td>
                  <td>
                    {invoiceDetail.currency_symbol}{' '}
                    {costingDetails?.triple_total_with_Pax}
                  </td>
                </tr>
              )}
               {costingDetailsChild?.triple_total_pax_child && Number(costingDetailsChild?.triple_total_pax_child) > 0 && (
                <tr className=''>
                  <td colspan='2' class='cs-primary_color  cs-f14'>
                   Childs in Triple Room{' '}
                   
                  </td>
                  <td class='cs-f14 fw-bold'>
                    {costingDetailsChild?.triple_total_pax_child} Childs
                  </td>
                  <td>
                   {invoiceDetail?.currency_symbol} {costingDetailsChild?.triple_grand_total_amount_child}
                  </td>
                  <td>
                    {invoiceDetail.currency_symbol}{' '}
                    {costingDetailsChild?.triple_total_with_Pax_child}
                  </td>
                </tr>
              )}
              {costingDetailsInfant?.triple_total_pax_infant   && Number(costingDetailsInfant?.triple_total_pax_infant  ) > 0 && (
                <tr className=''>
                  <td colspan='2' class='cs-primary_color  cs-f14'>
                     Infants in Triple Room{' '}
                   
                  </td>
                  <td class='cs-f14 fw-bold'>
                    {costingDetailsInfant?.triple_total_pax_infant } Infants
                  </td>
                  <td>
                   {invoiceDetail?.currency_symbol} {costingDetailsInfant?.triple_grand_total_amount_infant}
                  </td>
                  <td>
                    {invoiceDetail.currency_symbol}{' '}
                    {costingDetailsInfant?.triple_total_with_Pax_infant}
                  </td>
                </tr>
              )}
                {quadRoomQty > 0 && (
                <tr className=''>
                  <td colspan='2' class='cs-primary_color  cs-f14'>
                    <i className='fa-solid fa-bed'></i> Adults in Quad Room{' '}
                   
                  </td>
                  <td class='cs-f14 fw-bold'>
                    {costingDetails?.quad_total_pax} Adults
                  </td>
                  <td>
                   {invoiceDetail?.currency_symbol} {costingDetails?.quad_grand_total_amount}
                  </td>
                  <td>
                    {invoiceDetail.currency_symbol}{' '}
                    {costingDetails?.quad_total_with_Pax}
                  </td>
                </tr>
              )}
                {costingDetailsChild?.quad_total_pax_child && Number(costingDetailsChild?.quad_total_pax_child) > 0 && (
                <tr className=''>
                  <td colspan='2' class='cs-primary_color  cs-f14'>
                    Childs in Quad Room{' '}
                   
                  </td>
                  <td class='cs-f14 fw-bold'>
                    {costingDetailsChild?.quad_total_pax_child} Childs
                  </td>
                  <td>
                   {invoiceDetail?.currency_symbol} {costingDetailsChild?.quad_grand_total_amount_child}
                  </td>
                  <td>
                    {invoiceDetail.currency_symbol}{' '}
                    {costingDetailsChild?.quad_total_with_Pax_child}
                  </td>
                </tr>
              )}
              {costingDetailsInfant?.quad_total_pax_infant   && Number(costingDetailsInfant?.quad_total_pax_infant  ) > 0 && (
                <tr className=''>
                  <td colspan='2' class='cs-primary_color  cs-f14'>
                    Infants in Quad Room{' '}
                   
                  </td>
                  <td class='cs-f14 fw-bold'>
                    {costingDetailsInfant?.quad_total_pax_infant } Infants
                  </td>
                  <td>
                   {invoiceDetail?.currency_symbol} {costingDetailsInfant?.quad_grand_total_amount_infant}
                  </td>
                  <td>
                    {invoiceDetail.currency_symbol}{' '}
                    {costingDetailsInfant?.quad_total_with_Pax_infant}
                  </td>
                </tr>
              )}
                {withoutBedQty > 0 && (
                <tr className=''>
                  <td colspan='2' class='cs-primary_color  cs-f14'>
                    <i className='fa-solid fa-male'></i> Adults Without bed{' '}
                   
                  </td>
                  <td class='cs-f14 fw-bold'>
                    {costingDetails?.without_acc_total_pax} Adults
                  </td>
                  <td>
                   {invoiceDetail?.currency_symbol} {costingDetails?.without_acc_sale_price_single}
                  </td>
                  <td>
                    {invoiceDetail.currency_symbol}{' '}
                    {costingDetails?.without_acc_total_with_Pax}
                  </td>
                </tr>
              )}
                {costingDetailsChild?.child_total_pax && Number(costingDetailsChild?.child_total_pax) > 0 && (
                <tr className=''>
                  <td colspan='2' class='cs-primary_color  cs-f14'>
                     Childs Without bed{' '}
                   
                  </td>
                  <td class='cs-f14 fw-bold'>
                    {costingDetailsChild?.child_total_pax} Childs
                  </td>
                  <td>
                   {invoiceDetail?.currency_symbol} {costingDetailsChild?.child_total_sale_price}
                  </td>
                  <td>
                    {invoiceDetail.currency_symbol}{' '}
                    {costingDetailsChild?.child_total_with_Pax}
                  </td>
                </tr>
              )}
              {costingDetailsInfant?.infant_total_pax   && Number(costingDetailsInfant?.infant_total_pax  ) > 0 && (
                <tr className=''>
                  <td colspan='2' class='cs-primary_color  cs-f14'>
                    Infants Without bed{' '}
                   
                  </td>
                  <td class='cs-f14 fw-bold'>
                    {costingDetailsInfant?.infant_total_pax } Infants
                  </td>
                  <td>
                   {invoiceDetail?.currency_symbol} {costingDetailsInfant?.infant_total_sale_price}
                  </td>
                  <td>
                    {invoiceDetail.currency_symbol}{' '}
                    {costingDetailsInfant?.infant_total_with_Pax}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}
export default PerPerson
