import React, { useState, useEffect } from 'react'
import { Sidenav, Nav } from 'rsuite'
import DashboardIcon from '@rsuite/icons/legacy/Dashboard'
import GearCircleIcon from '@rsuite/icons/legacy/GearCircle'
import Profile from '@rsuite/icons/legacy/Profile'
import CheckCircle from '@rsuite/icons/legacy/CheckCircle'
import SignOut from '@rsuite/icons/legacy/SignOut'
import FileUpload from '@rsuite/icons/legacy/FileUpload'
import Layout from '../../Components/Layout/Layout'
import DashboardHome from './Home'
import IncomeStatement from './IncomeStatement'
import AccountStatement from './AccountStatement'
import Settingage from './Setting'
import ArrivalList from './ArrivalList'
import {
  Routes,
  Route,
  useNavigate,
  NavLink,
  useLocation
} from 'react-router-dom'
import axios from 'axios'
import { ApiEndPoint } from '../../Components/GlobalData/GlobalData'
import ErrorBoundary from './error'
function Dashboard () {
  const navigation = useNavigate()
  const location = useLocation()
  const endpoint = ApiEndPoint()
  const [dashboardData, setDashboardData] = useState([])
  const [incomList, setIncomList] = useState([])
  var UserDetail = JSON.parse(localStorage.getItem('agentdata'))
  const [activeKey, setActiveKey] = useState('')
  useEffect(() => {
    const currentPath = location.pathname
    if (currentPath === '/dashboard') {
      setActiveKey('1')
    } else if (currentPath === '/dashboard/statement') {
      setActiveKey('2')
    } else if (currentPath === '/dashboard/change_password') {
      setActiveKey('3')
    } else if (currentPath === '/dashboard/bookings') {
      setActiveKey('5')
    }
  }, [location])
  const handleNavItemSelect = eventKey => {
    setActiveKey(eventKey)
    if (eventKey === '1') {
      navigation('/dashboard')
    } else if (eventKey === '2') {
      navigation('/dashboard/statement')
    } else if (eventKey === '3') {
      navigation('/dashboard/change_password')
    } else if (eventKey === '5') {
      navigation('/dashboard/bookings')
    }
  }
  useEffect(() => {
    GetUserBooking()
  }, [])
  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("agentdata");
    localStorage.removeItem("loginTime");
    navigation('/login')
  }
  const GetUserBooking = async () => {
    const data = {
      token: UserDetail.token,
      b2b_agent_id: UserDetail.id
    }
    try {
      const response = await axios.post(
        endpoint + '/api/view_customer_hotel_booking',
        data
      )
      if (response.data.message === 'success') {
        const mergedArray = [
          ...response.data.hotels_bookings.map(obj => ({
            ...obj,
            domain_name: 'Hotel Booking'
          })),
          ...response.data.invoice_Bookings.map(obj => ({
            ...obj,
            domain_name: 'Inovice Booking'
          })),
          ...response.data.transfer_Bookings.map(obj => ({
            ...obj,
            domain_name: 'Transfer Booking'
          }))
        ]
        setDashboardData(mergedArray)
        const confirmlist = response.data.inv_details.filter(
          item => item.status === 'CONFIRMED'
        )
        setIncomList(confirmlist)
      }
    } catch (error) {
      console.log(error)
      console.error('Error:', error)
    }
  }
  return (
    <Layout>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-lg-3 cold-md-3'>
            <div style={{backgroundColor:'#f7f7fa'}} className='text-center pt-3 pb-3'>
              <h4 style={{ color: '#000' }}>
                {UserDetail.first_name} {UserDetail.last_name}
              </h4>
              <p>{UserDetail.phone_no}</p>
              <p>{UserDetail.email}</p>
            </div>
            <Sidenav defaultOpenKeys={['3', '4']}>
              <Sidenav.Body>
                <Nav activeKey={activeKey} onSelect={handleNavItemSelect}>
                  <Nav.Item eventKey='1' icon={<DashboardIcon />}>
                    Dashboard
                  </Nav.Item>
                  <Nav.Item eventKey='5' icon={<CheckCircle />}>
                    My Bookings
                  </Nav.Item>
                  <Nav.Item eventKey='2' icon={<Profile />}>
                    Account Statement
                  </Nav.Item>
                  <Nav.Item eventKey='3' icon={<GearCircleIcon />}>
                    Change Password
                  </Nav.Item>
                  <Nav.Item onClick={logout} eventKey='4' icon={<SignOut />}>
                    Logout
                  </Nav.Item>
                </Nav>
              </Sidenav.Body>
            </Sidenav>
          </div>
          <div className='col-lg-9 cold-md-9'>
            <Routes>
              <Route
                index
                element={<ErrorBoundary><ArrivalList BookingList={dashboardData}/></ErrorBoundary>}
              />
               <Route
                path='bookings'
                element={<DashboardHome invoiceListData={dashboardData} />}
              />
              <Route path='statement' element={<AccountStatement />} />
              <Route path='change_password' element={<Settingage />} />
            </Routes>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default Dashboard
